import { createSelector } from 'reselect';
import { selectCurrentInstance, selectInstanceEditingState } from 'structured-content-lib/redux/selectors/instanceEditSelectors';
import { getIsUngatedForInlineDataTokenEditing } from './authSelectors';
import { getDynamicPageDataSourceId } from './baseContentModelSelectors';
import { getPublishedTablesSortedByPublishedTime, getTablesUsedForPages } from '../../containers/hubdb/utils';
export const getAllTables = state => state.pageSettings.get('availableHubDbTables').toJS();
export const getTableId = (state, props) => props.tableId;
export const getSelectedTable = (state, props) => state.structuredContent && state.structuredContent.instanceEditing && state.structuredContent.instanceEditing.hubDbTable || state.pageSettings.get('availableHubDbTables').toJS().find(t => t.id.toString() === props.tableId);
export const getHubDBTableSchemaColumns = createSelector([getSelectedTable], selectedTable => selectedTable.columns);
export const getAllTablesSortedByPublished = createSelector(getAllTables, getPublishedTablesSortedByPublishedTime);
export const getSortByPublishedAndDynamic = createSelector(getAllTablesSortedByPublished, getTablesUsedForPages);
export const selectForeignTableOptions = createSelector([getSortByPublishedAndDynamic, getTableId], (tables, tableId) => {
  if (!tables) return [];
  return tables.filter(table => table.id.toString() !== tableId).map(table => {
    return [table.id, table.label];
  });
});
export const getTableAsModuleSchema = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.tableSchema);
export const getTableRows = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.tableRows);
export const getActiveHubDbRowId = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.currentHubDbRowId);
export const getCurrentRow = createSelector([getTableRows, getActiveHubDbRowId], (rows, activeId) => rows ? rows.find(row => row.id === activeId) : null);
export const getColumnNameToIdMap = createSelector([getTableAsModuleSchema], tableAsModuleSchema => {
  if (!tableAsModuleSchema) {
    return {};
  }
  return tableAsModuleSchema.fields.reduce((columnNamesToIds, currentField) => {
    columnNamesToIds[currentField.name] = currentField.id;
    return columnNamesToIds;
  }, {});
});
export const getCurrentHubDbFieldsAsSimpleMap = createSelector([selectCurrentInstance, getTableAsModuleSchema], (currentRow, tableSchema) => {
  const fields = [];
  if (currentRow) {
    tableSchema.fields.forEach(field => {
      const rowValueIndex = parseInt(field.id, 10);
      const value = currentRow.values[rowValueIndex];
      fields.push({
        name: field.name,
        id: field.id,
        type: field.type,
        value
      });
    });
  }
  return fields;
});
export const getCurrentHubDbFields = createSelector([getTableAsModuleSchema], tableSchema => {
  return tableSchema ? tableSchema.fields : [];
});
export const getInstanceEditingPreviewParams = createSelector([getActiveHubDbRowId], hubDbRowId => {
  // Mostly a means by which downstream iframe url selectors continue to work
  // even if the instance editing reducer is not in this editor
  return hubDbRowId ? {
    hubdb_row_id: hubDbRowId
  } : {};
});
export const getIsInstanceEditingModeActive = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.instanceEditingModeActive);
export const getIsDataAuthoringModeActive = createSelector([selectInstanceEditingState], instanceEditing => instanceEditing && instanceEditing.instanceEditingModeActive && instanceEditing.dataAuthoringModeActive);
export const getShouldShowInstanceEditingMode = createSelector([getIsUngatedForInlineDataTokenEditing, getDynamicPageDataSourceId], (isUngatedForInlineDataTokenEditing, dynamicPageDataSourceId) => {
  return isUngatedForInlineDataTokenEditing && !!dynamicPageDataSourceId;
});
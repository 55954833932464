import { createSelector } from 'reselect';
import { getActiveDomainName, getContentModelDomain } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContextActiveDomain, getContextPrimaryDomain, getContextSecondaryToDomain
// @ts-expect-error not yet migrated
} from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { createHasScopeSelector, getIsProductIncompatibleWithPrerendering } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getAllowDomainChangeForNinetyDaysAfterDowngrade } from 'ContentEditorUI/redux/selectors/portalSelectors';
import { domainIsLegacy, domainIsStaging, domainIsFreePageBuilder, domainIsContentEditDomain, domainIsContextActiveDomain, getProtocol } from 'ContentEditorUI/utils/domainsUtils';
// @ts-expect-error not yet migrated
import { getIsDomainChangeAllowedAfterDowngrades } from './downgradeSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsLandingOrSitePage } from './contentReadOnlyDataSelectors';
//
// Selectors
//
export const getDomainsState = basicSelector(state => state.portalDomains);
export const getAvailableDomains = createSelector([getDomainsState], domainsState => domainsState.domains);
export const getDomainExclusionReason = createSelector([getDomainsState], domainsState => domainsState.domainExclusionReason);
export const getHasMultiDomainScope = createHasScopeSelector('multi-domain-publishing');
export const getHasContentCustomDomainWrite = createHasScopeSelector('content-custom-domain-write');
export const getAvailableStagingDomains = createSelector([getAvailableDomains], availableDomains => {
  return availableDomains.filter(d => {
    return domainIsStaging(d);
  });
});
export const getDomainsNotLegacyOrStaging = createSelector([getAvailableDomains], availableDomains => availableDomains.filter(domain => {
  return !(domainIsStaging(domain) || domain.isStagingDomain ||
  // for more details about this confusing condition read here: https://git.hubteam.com/HubSpot/ContentEditorUI/pull/8945#discussion_r1006357
  domainIsLegacy(domain));
}));

// Gets the domain object of the domain content model or
// the active domain object that is returned from context onload if not set
export const getActiveDomain = createSelector([getContentModelDomain, getContextActiveDomain, getAvailableDomains], (contentModelDomainName, contextActiveDomain, domains) => {
  if (contentModelDomainName) {
    const contentModelDomain = domains.find(d => d.domain === contentModelDomainName);
    if (contentModelDomain) {
      return contentModelDomain;
    }
  }
  return contextActiveDomain;
});
export const getActiveApexDomain = createSelector(getActiveDomain, activeDomain => {
  return domainIsContentEditDomain(activeDomain) ? activeDomain.apex_domain : activeDomain.apexDomain;
});
export const getActiveDomainIsConnected = createSelector([getActiveDomain], activeDomain => {
  // Have to account for context active domain (snake case) and
  // domain object from domains list (camel case)
  return domainIsContentEditDomain(activeDomain) ? activeDomain.is_resolving && activeDomain.is_dns_correct : activeDomain.isResolving && activeDomain.isDnsCorrect;
});
export const getActiveDomainIsInternal = createSelector([getActiveDomain], activeDomain => domainIsContextActiveDomain(activeDomain) ? activeDomain.isInternalDomain : activeDomain.is_internal_domain);
export const getActiveDomainIsConnectedAndExternal = createSelector([getActiveDomainIsInternal, getActiveDomainIsConnected], (isActiveDomainInternal, isActiveDomainConnected) => !isActiveDomainInternal && isActiveDomainConnected);

// This also determines whether this is a staging page or not
export const getActiveStagingDomain = createSelector([getAvailableStagingDomains, getActiveDomainName], (availableStagingDomains, activeDomainName) => availableStagingDomains.find(availableStagingDomain => availableStagingDomain.domain === activeDomainName));
export const getFullDomainPath = createSelector([getAvailableDomains, getActiveDomainName, getContextActiveDomain], (availableDomains, contentModalDomain, contextActiveDomain) => {
  const domain = availableDomains.find(availableDomain => availableDomain.domain === contentModalDomain) || contextActiveDomain;
  const protocol = getProtocol(domain);
  return `${protocol}${domain.domain}`;
});
export const getPagePrimaryDomain = createSelector([getContextPrimaryDomain], primaryDomain => primaryDomain);
export const getPageSecondaryToDomain = createSelector([getContextSecondaryToDomain], secondaryToDomain => secondaryToDomain);
export const getFreePageBuilderDomain = createSelector(getDomainsNotLegacyOrStaging, domainsNotLegacyOrStaging => domainsNotLegacyOrStaging.filter(domainIsFreePageBuilder)[0]);
export const getActiveStagingOrPagePrimaryDomain = createSelector([getActiveStagingDomain, getPagePrimaryDomain], (activeStagingDomain, pagePrimaryDomain) => activeStagingDomain || pagePrimaryDomain);
export const getIsPageOnPagebuilderDomain = createSelector([getActiveDomain], activeDomain => domainIsFreePageBuilder(activeDomain));

// To warn customers that they should not publish pages on pagebuilder domain
// because they have access to features which may be incompatible with pre-rendering
export const getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct = createSelector([getIsPageOnPagebuilderDomain, getIsProductIncompatibleWithPrerendering, getIsLandingOrSitePage], (isPageOnPagebuilderDomain, isProductIncompatibleWithPrerendering, isLandingOrSitePage) => isLandingOrSitePage && isPageOnPagebuilderDomain && isProductIncompatibleWithPrerendering);
const addContextActiveDomainToDomains = (domains, contextActiveDomain) => {
  const contextActiveDomainName = contextActiveDomain.domain;

  // Add the context active domain (domain content is assigned on load)
  // to the options always if it isn't already there
  if (domains.some(d => d.domain === contextActiveDomainName)) {
    return domains;
  }
  return [contextActiveDomain, ...domains];
};
const _getAvailableStagingDomainsForPage = createSelector([getHasMultiDomainScope, getActiveStagingDomain, getAvailableStagingDomains, getContextActiveDomain], (hasMultiDomainScope, activeStagingDomain, availableStagingDomains, contextActiveDomain) => hasMultiDomainScope ? addContextActiveDomainToDomains(availableStagingDomains, contextActiveDomain) : [activeStagingDomain] // Users cannot change domain for staged pages when they do not have multi-domain access
);
const _getAvailableDomainsForPage = createSelector([getHasMultiDomainScope, getAllowDomainChangeForNinetyDaysAfterDowngrade, getDomainsNotLegacyOrStaging, getPagePrimaryDomain, getActiveDomainName, getContextActiveDomain], (hasMultiDomainScope, allowDomainChangeAfterDowngrade, domainsNotLegacyOrStaging, pagePrimaryDomain, contentModelDomain, contextActiveDomain) => {
  let availableDomainOptions;
  if (hasMultiDomainScope || allowDomainChangeAfterDowngrade) {
    availableDomainOptions = domainsNotLegacyOrStaging;
  } else {
    availableDomainOptions = domainsNotLegacyOrStaging.filter(d => {
      return d.domain === contentModelDomain || d.domain === pagePrimaryDomain.domain;
    });
  }
  return addContextActiveDomainToDomains(availableDomainOptions, contextActiveDomain);
});
export const getAvailableDomainsForPage = createSelector([getActiveStagingDomain, _getAvailableStagingDomainsForPage, _getAvailableDomainsForPage], (activeStagingDomain, availableStagingDomainsForPage, availableDomainOptions
// Explicitly overriding the return type `(ContentEditDomain | Domain)[] | (ContentEditDomain | undefined)[]`
// since on TS versions < 5.2 we're unable to use certain array methods like `every`.
) => {
  if (activeStagingDomain) {
    return availableStagingDomainsForPage;
  }
  return availableDomainOptions;
});
export const getAvailableConnectedDomainsForPageCount = createSelector([getAvailableDomainsForPage], availableDomains => {
  return availableDomains.filter(domain => domain && domainIsContentEditDomain(domain) && domain.is_internal_domain === false).length;
});
export const getIsViolatingMultiDomainAccess = createSelector([getActiveDomainName, getPagePrimaryDomain, getActiveStagingDomain, getHasMultiDomainScope], (contentModelDomain, pagePrimaryDomain, activeStagingDomain, hasMultiDomainScope) => {
  if (!hasMultiDomainScope) {
    if (activeStagingDomain) {
      return false;
    }
    if (contentModelDomain && pagePrimaryDomain) {
      return contentModelDomain !== pagePrimaryDomain.domain;
    }
  }
  return false;
});

// Invalid domain is when on load, the content model has a domain that is
// not the same as context.active_domain. We can compute if it's invalid
// bc if it's not in the result of getAvailableDomainsForPage (which includes
// active_domain), then the domain doesn't exist. This is opposed to a normal
// situation where the active_domain/content domain is a domain that is not
// included in the domains response, which is valid, but the user can't publish.
export const getContentModelHasValidDomain = createSelector([getContentModelDomain, getAvailableDomainsForPage], (contentModelDomain, allValidDomainsForPage) => !contentModelDomain || allValidDomainsForPage.some(d => d && d.domain === contentModelDomain));

// Check if current domain is not permitted for this user to publish on
export const getCurrentDomainIsNotPermitted = createSelector([getActiveDomainName, getAvailableDomains, getContentModelHasValidDomain], (activeDomainName, availableDomains, contentModelHasValidDomain) =>
// Want to avoid showing unauthorized error if content model has invalid
// domain, so that we can display a validation error instead for them to fix
contentModelHasValidDomain && !availableDomains.some(d => d.domain === activeDomainName));
export const getCanOnlyChangeToPrimaryDomain = createSelector([getIsDomainChangeAllowedAfterDowngrades, getHasMultiDomainScope], (isDomainChangeAllowedAfterDowngrades, hasMultiDomainScope) => !isDomainChangeAllowedAfterDowngrades && !hasMultiDomainScope);
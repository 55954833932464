export const SENTRY_KEYS = {
  CAMPAIGN_WITH_STEPS_NOT_LOADED: 'CAMPAIGN_WITH_STEPS_NOT_LOADED',
  CAMPAIGN_GOALS_MODAL_ADDITIONAL_PROPERTIES_NOT_LOADED: 'CAMPAIGN_GOALS_MODAL_ADDITIONAL_PROPERTIES_NOT_LOADED',
  CAMPAIGN_GOALS_MODAL_CREATE_FAILED: 'CAMPAIGN_GOALS_MODAL_CREATE_FAILED',
  FRAMEWORK_APP_SETTINGS_FETCH_FAILED: 'FRAMEWORK_APP_SETTINGS_FETCH_FAILED',
  OBJECT_DEFINITION_FETCH_FAILED: 'OBJECT_DEFINITION_FETCH_FAILED',
  ASSOCIATED_CAMPAIGN_FETCH_FAILED: 'ASSOCIATED_CAMPAIGN_FETCH_FAILED',
  CAMPAIGNS_PLATFORM_UPDATE_FAILED: 'CAMPAIGNS_PLATFORM_UPDATE_FAILED',
  BACK_TO_CAMPAIGN_LINK_FAILED: 'BACK_TO_CAMPAIGN_LINK_FAILED',
  ERROR_GETTING_LIFECYCLE_STAGES_LIST: 'ERROR_GETTING_LIFECYCLE_STAGES_LIST'
};
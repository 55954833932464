export const BORDER_STYLES = Object.freeze({
  NONE: 'none',
  SOLID: 'solid',
  DOTTED: 'dotted',
  DASHED: 'dashed',
  DOUBLE: 'double'
});
export const BORDER_SIDES = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
});
export const BORDER_PROPERTIES = Object.freeze({
  WIDTH: 'width',
  STYLE: 'style',
  COLOR: 'color',
  OPACITY: 'opacity'
});
export const VERTICAL_SIDES = Object.freeze({
  BOTTOM: 'BOTTOM',
  TOP: 'TOP'
});
export const HORIZONTAL_SIDES = Object.freeze({
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
});
export const ZERO_WIDTH_BORDER_PROPERTY = Object.freeze({
  value: 0,
  units: 'px'
});
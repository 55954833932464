import http from 'hub-http/clients/apiClient';
import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';

/**
 * GET forms/v2/forms/{formGuid}
 * @param {string} formGuid Form GUID
 * @return {Promise} fetch a single form
 */
export function fetchForm(formGuid, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`forms/v2/forms/${formGuid}`);
}

/**
 * GET content-editor/v1/forms
 * @param {object} query Query params
 * @return {Promise} fetch multiple forms
 */
export function fetchFilteredForms(query = {}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('content-editor/v1/forms', {
    query
  });
}

/**
 * POST forms/v2/generate
 * @param {string} name Formname
 * @return {Promise} create form
 */

export function createForm(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`forms/v2/generate`, {
    data: {
      name
    }
  });
}
/**
 * GET forms/v2/count
 * @param {object} query Query params
 * @return {Promise} get total forms count
 */
export function fetchFormsCount(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('forms/v2/forms/count', {
    query
  });
}

/**
 * GET forms/v2/forms-drafts
 * @param {string} formGuid Form GUID
 * @return {Promise} fetch form draft
 */
export function fetchFormDraft(formGuid, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`forms/v2/forms-drafts/${formGuid}`).then(({
    formDraft,
    form
  }) => formDraft || form);
}

/**
 * PUT forms/v2/form-drafts/save
 * @param {string} formGuid Form GUID
 * @param {object} formDefinition Form Definition
 * @param {number} knownUpdatedAt Updated timestamp from definition
 */
export function saveFormDraft(formGuid, formDefinition, ...rest) {
  const {
    updatedAt
  } = formDefinition;
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`forms/v2/forms-drafts/save/${formGuid}?knownUpdatedAt=${updatedAt}`, {
    data: formDefinition
  });
}

/**
 * GET properties/v4/groups/contact/with-statistics
 * @return {Promise} fetch contact properties
 */
export function fetchContactProperties(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('properties/v4/groups/contact/with-statistics').then(({
    results
  }) => results);
}

/**
 * GET email/v1/subscriptions/definitions
 * @return {Promise} fetch email subscriptions
 */
export function fetchSubscriptionDefinitions(...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('subscriptions/v1/definitions');
}

/**
 * GET cosindex/api/v1/connections/parents
 * @param {string} formGuid Form GUID
 * @return {Promise}
 */
export function fetchCMSParentPages(formGuid, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`cosindex/api/v1/connections/parents?id=${formGuid}`);
}

/**
 * POST /v2/forms/{guid}/clone
 * @param {string} formGuid Form GUID
 * @return {Promise}
 */
export function cloneForm(formGuid, name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`forms/v2/forms/${formGuid}/clone`, {
    data: {
      name
    }
  });
}
export const fetchFormsFromCrmSearch = ({
  count = 5,
  query,
  filterGroups
}) => http.post('crm-search/search', {
  data: Object.assign({
    count,
    requestOptions: {
      properties: ['hs_form_id', 'hs_name', 'hs_embed_version']
    },
    objectTypeId: '0-28'
  }, filterGroups ? {
    filterGroups
  } : {}, query ? {
    query
  } : {}, {
    sorts: [{
      property: 'hs_updated_at',
      order: 'DESC'
    }]
  })
});
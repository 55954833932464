import http from 'hub-http/clients/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'ContentEditorUI/utils/routingUtils';
// @ts-expect-error Untyped module
import { openModal } from 'ContentEditorUI/redux/actions/appActions';
import { getIsUngatedForAiLpThankYouPageReminder } from '../redux/selectors/authSelectors';
import { registerQuery, useLazyQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
const isValidContentId = contentIdFromParams => typeof contentIdFromParams === 'string' && /^[0-9]+$/.test(contentIdFromParams);
const requestFetchPageById = ({
  contentId
}) => http.get(`cospages/v2/landing-pages/${contentId}`);
const FETCH_PAGE_BY_ID = registerQuery({
  fieldName: 'fetchPageById',
  args: ['contentId'],
  fetcher: requestFetchPageById
});
export const useAiLpThankYouPageReminder = () => {
  const dispatch = useDispatch();
  const {
    aiLpGeneratedThankYouPageId
  } = useQueryParams();
  const isUngatedForReminder = useSelector(getIsUngatedForAiLpThankYouPageReminder);
  const isThankYouPageValid = isValidContentId(aiLpGeneratedThankYouPageId);
  const isEligibleForThankYouPageReminder = isUngatedForReminder && isThankYouPageValid;
  const [openModalIfThankYouPageIsDraft] = useLazyQuery(FETCH_PAGE_BY_ID, {
    variables: {
      contentId: aiLpGeneratedThankYouPageId
    },
    fetchPolicy: 'network-only',
    onCompleted: ({
      fetchPageById: {
        isDraft
      }
    }) => {
      if (isDraft) {
        dispatch(openModal(MODAL_TYPES.AILP_THANK_YOU_PAGE_REMINDER));
      }
    },
    onError: ({
      networkError
    }) => {
      Raven.captureMessage('[AILP] - Unable to fetch thank you page publishing state', {
        extra: {
          errorMessage: networkError === null || networkError === void 0 ? void 0 : networkError.message,
          contentId: aiLpGeneratedThankYouPageId
        }
      });
    }
  });
  return {
    openModalIfThankYouPageIsDraft,
    isEligibleForThankYouPageReminder
  };
};
'use es6';

import { getFullUrl } from 'hubspot-url-utils';
import { parse } from 'hub-http/helpers/params';
import { memoize } from 'underscore';
export const constructSearchStringFromUrlParams = urlParams => {
  if (!urlParams || Object.keys(urlParams).length === 0) {
    return '';
  }
  return `?${Object.keys(urlParams).map(key => `${encodeURI(key)}=${encodeURIComponent(urlParams[key])}`).join('&')}`;
};
export const parseQueryString = memoize(queryString => {
  if (!queryString) {
    return {};
  }
  if (queryString.startsWith('?')) {
    return parse(queryString.substring(1));
  }
  return parse(queryString);
});
export const getAppDomain = () => getFullUrl('app');
'use es6';

import { createSelector } from 'reselect';
import { getInitialUserAttributesRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestCompleted } from 'redux-request-state/util/requestStateCheckers';
import { getHasSeenScopesHaveChangedModal as getLocalSeenScopesHaveChangedModal, getHasSeenOnboardingModalFromSuperStore, getHasSeenPatagoniaFeedbackCountFromSuperStore, getHasRespondedPatagoniaFeedbackFromSuperStore, getHasSeenEditorCsatCountFromSuperstore, getEditorCsatSubmissionDateFromSuperstore, getHasSeenBlogPlgOnboardingTourFromSuperStore } from './localCachedSettingsSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getSettings = basicSelector(state => state.baseSettings || state.pageSettings);
export const getHasFetchedInitialUserAttributes = createSelector(getInitialUserAttributesRequest, initialUserAttributesRequest => requestCompleted(initialUserAttributesRequest));
export const getHasSeenScopesHaveChangedModal = createSelector([getLocalSeenScopesHaveChangedModal], seenState => !!seenState);
export const getHasSeenBlogOnboardingPlgTourLocal = createSelector([getHasSeenBlogPlgOnboardingTourFromSuperStore], seenState => {
  return !!seenState;
});
export const getHasSeenOnboardingModalLocal = createSelector([getHasSeenOnboardingModalFromSuperStore], seenState => {
  return !!seenState;
});
export const getHasSeenPatagoniaFeedbackCount = createSelector([getHasSeenPatagoniaFeedbackCountFromSuperStore], hasSeenPatagoniaFeedbackCount => {
  return parseInt(hasSeenPatagoniaFeedbackCount, 10) || 0;
});
export const getHasRespondedPatagoniaFeedback = createSelector([getHasRespondedPatagoniaFeedbackFromSuperStore], hasResponded => {
  return Boolean(hasResponded);
});
export const getHasSeenEditorCsatCount = createSelector([getHasSeenEditorCsatCountFromSuperstore], hasSeenEditorCsatCount => {
  return parseInt(hasSeenEditorCsatCount, 10) || 0;
});
export const getHasSubmittedEditorCsat = createSelector([getEditorCsatSubmissionDateFromSuperstore], submissionDate => {
  return Boolean(submissionDate);
});
require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "page-editor-ui/lang",
  context: {
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./da.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./de.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-de" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-en" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./es.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./fi.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-fi" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./fr.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-fr" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./it.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./ja.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-ja" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./nl.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./no.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./pl.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./pt-br.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-pt-br" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./sv.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-sv" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1438/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./zh-tw.lyaml?sporks" /* webpackChunkName: "page-editor-ui-lang-zh-tw" */); }  }
};

import { getFromIDB, setInIDB } from 'framework-data-schema-quick-fetch';
import { Metrics } from '../metrics';
export const trackIfChanged = ({
  cacheKey,
  cacheName,
  segment,
  isEqual
}) => {
  if (!segment) {
    return Promise.resolve();
  }
  return getFromIDB(cacheKey, 'metrics').catch(() => 0).then(result => {
    if (isEqual) {
      // Increment the identical request counter by one
      return setInIDB(cacheKey, result + 1, 'metrics');
    } else {
      // Report the count of identical requests that we reached
      Metrics.histogram(`${cacheName}-duplicate-data-request-count`, {
        segment
      }).update(result);

      // Reset our count to 0
      return setInIDB(cacheKey, 0, 'metrics');
    }
  });
};
// @ts-expect-error not yet migrated
import defaultConfig from 'ContentEditorUI/redux/inpageSync/defaultConfig';
class SyncConfigManager {
  constructor(configBuilder) {
    this.configBuilder = configBuilder;
  }
  clear() {
    this.syncConfig = undefined;
  }
  getConfig() {
    if (this.syncConfig === undefined) {
      this.syncConfig = this.configBuilder();
    }
    return this.syncConfig;
  }
}
export { SyncConfigManager as BaseSyncConfigManager };
const SyncConfigManagerInstance = new SyncConfigManager(defaultConfig);
export default SyncConfigManagerInstance;
import { createSelector } from 'reselect';
import { gate } from 'hub-http/gates';
export const getAuth = state => state.auth;
export const getUser = createSelector([getAuth], auth => auth.user);
export const getGates = createSelector([getAuth], auth => auth.gates);
export const getScopes = createSelector([getUser], user => user.scopes);
export const createHasScopeSelector = scopeToCheck => createSelector([getScopes], scopes => scopes.indexOf(scopeToCheck) > -1);
export const createHasGateSelector = gateToCheck => createSelector([getGates], gates => gates.indexOf(gateToCheck) > -1);
export const getHasCampaignsReadAccess = createHasScopeSelector('campaigns-access');
export const getHasCampaignsWriteAccess = createHasScopeSelector('campaigns-write');
export const getIsUngatedForInlineDataTokenEditing = createHasGateSelector('ContentEditorUI:InlineDataTokenEditing');
export const getIsUngatedForContentHubAssociations = createHasGateSelector('Campaigns:ContentHubAssociations');
export const getIsUngatedForScpModuleInsertion = () => createHasGateSelector('StructuredContent:InlineModuleInsertion');

// V3 gates need to use the gate function to check for access - https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
export const getIsUngatedForCodeField = createSelector([getGates], gates => gates.includes(gate('StructuredContentPlatform:CodeField')));
'use es6';

import { mergeModuleData, setLayoutFragmentLabel } from 'ContentEditorUI/redux/actions/moduleActions';
import { SCROLL_TO_LAYOUT_FRAGMENT, CLEAR_SCROLL_TO_NODE_POSITION_INFO, OPEN_TREE_NODES, CLOSE_TREE_NODES, SET_SIDEBAR_SEARCH_TERM, SET_SIDEBAR_ACTIVE_MATCH, SET_SIDEBAR_MATCH_IDS, OPEN_EXPANDABLE_MENU, CLEAR_OPEN_EXPANDABLE_MENU, SAVE_SIDEBAR_SCROLL_TOP, SHOW_ALL_ROWS_FOR_DND_AREA, SHOW_STANDARD_ROWS_FOR_DND_AREA } from 'ContentEditorUI/redux/actions/actionTypes';
export const saveTreeNodeName = (nodeId, newLabel, isModule, isRow) => dispatch => {
  if (isModule) {
    dispatch(mergeModuleData(nodeId, {
      label: newLabel
    }));
  } else {
    dispatch(setLayoutFragmentLabel(nodeId, newLabel, isRow));
  }
};
export const scrollToLayoutFragment = (nodePositionId, layoutSectionId) => dispatch => {
  dispatch({
    type: SCROLL_TO_LAYOUT_FRAGMENT,
    payload: {
      nodePositionId,
      layoutSectionId
    }
  });
  setTimeout(() => {
    dispatch({
      type: CLEAR_SCROLL_TO_NODE_POSITION_INFO
    });
  }, 2000);
};
export const openTreeNodes = nodesToOpen => ({
  type: OPEN_TREE_NODES,
  payload: {
    nodesToOpen
  }
});
export const closeTreeNodes = nodesToClose => ({
  type: CLOSE_TREE_NODES,
  payload: {
    nodesToClose
  }
});
export const setSidebarSearchTerm = newSearchTerm => ({
  type: SET_SIDEBAR_SEARCH_TERM,
  payload: {
    newSearchTerm
  }
});
export const setSidebarActiveMatch = newActiveMatch => ({
  type: SET_SIDEBAR_ACTIVE_MATCH,
  payload: {
    newActiveMatch
  }
});
export const setSidebarMatchIds = newMatchIds => ({
  type: SET_SIDEBAR_MATCH_IDS,
  payload: {
    newMatchIds
  }
});
export const openExpandableMenu = newOpenExpandableMenuNodeId => ({
  type: OPEN_EXPANDABLE_MENU,
  payload: {
    newOpenExpandableMenuNodeId
  }
});
export const clearOpenExpandableMenu = () => ({
  type: CLEAR_OPEN_EXPANDABLE_MENU
});
export const saveSidebarScrollTop = newSidebarScrollTop => ({
  type: SAVE_SIDEBAR_SCROLL_TOP,
  payload: {
    newSidebarScrollTop
  }
});
export const showAllRowsForDndArea = dndAreaId => ({
  type: SHOW_ALL_ROWS_FOR_DND_AREA,
  payload: {
    idToAdd: dndAreaId
  }
});
export const showStandardRowsForDndArea = dndAreaId => ({
  type: SHOW_STANDARD_ROWS_FOR_DND_AREA,
  payload: {
    idToRemove: dndAreaId
  }
});
'use es6';

import flatten from 'hs-lodash/flatten';
import { isImmutable } from './dataHelpers';
const forEachTreePOJO = (layoutTrees, callback) => {
  if (!layoutTrees || !callback) {
    return;
  }
  Object.entries(layoutTrees).forEach(([key, tree]) => {
    callback(tree, key);
  });
};

// Calls the given callback function on every tree in the trees map
export const forEachTree = (layoutTrees, callback) => {
  const isTreeImmutable = layoutTrees && isImmutable(layoutTrees);
  if (layoutTrees && !isTreeImmutable) {
    forEachTreePOJO(layoutTrees, callback);
    return;
  }
  if (callback && layoutTrees && layoutTrees.size) {
    const keys = layoutTrees.keySeq().toArray();
    keys.forEach(key => {
      const tree = layoutTrees.get(key);
      callback(tree, key);
    });
  }
};
const columnMapper = (columns, {
  depth,
  nodeCountRef,
  isFirstRow,
  isLastRow,
  parentRowIndex,
  parentRowId,
  parentRowHasRoomForMoreColumns,
  grandParentColumnId,
  rowCallback,
  columnCallback,
  numberColumnsInGrid
}) => {
  const columnIds = columns.map(c => c.getName());
  const result = columns.map((column, i) => {
    nodeCountRef.value += 1;
    const isFirstColumn = i === 0;
    const isLastColumn = i === columns.length - 1;
    const previousColumnId = isFirstColumn ? null : columnIds[i - 1];
    const nextColumnId = isLastColumn ? null : columnIds[i + 1];
    const numChildRows = column.getNumberRows();
    const firstChildRowId = column.hasRows() ? column.getRowNames()[0] : undefined;
    const lastChildRowId = column.hasRows() ? column.getRowNames()[numChildRows - 1] : undefined;
    const rowsCallbackForThisColumn = () => {
      if (numChildRows === 0) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return rowMapper(column.getRows(), {
        parentColumnId: column.getName(),
        depth: depth + 1,
        nodeCountRef,
        rowCallback,
        columnCallback,
        numberColumnsInGrid
      });
    };
    let results = [];
    results = results.concat(columnCallback({
      i,
      depth,
      nodeCounter: nodeCountRef.value,
      column,
      columnId: column.getName(),
      previousColumnId,
      nextColumnId,
      unitsWide: column.getWidth(),
      parentId: column.getParentName(),
      parentRowId,
      parentRowHasRoomForMoreColumns,
      numChildRows,
      firstChildRowId,
      lastChildRowId,
      isFirstRow,
      isLastRow,
      parentRowIndex,
      grandParentColumnId,
      rowCallback: rowsCallbackForThisColumn
    }));
    return results;
  });
  return flatten(result);
};
const rowMapper = (rows, {
  parentColumnId,
  depth,
  nodeCountRef,
  rowCallback,
  columnCallback,
  numberColumnsInGrid
}) => {
  const rowIds = rows.map(r => r.getName());
  const result = rows.map((row, i) => {
    nodeCountRef.value += 1;
    const isFirstRow = i === 0;
    const isLastRow = i === rows.length - 1;
    const rowId = rowIds[i];
    const previousRowId = isFirstRow ? null : rowIds[i - 1];
    const nextRowId = isLastRow ? null : rowIds[i + 1];
    const numChildColumns = row.getNumberColumns();
    const hasRoomForMoreColumns = numberColumnsInGrid > numChildColumns;
    const columnsCallbackForThisRow = () => {
      return columnMapper(row.getColumns(), {
        depth: depth + 1,
        nodeCountRef,
        isFirstRow,
        isLastRow,
        parentRowIndex: i,
        parentRowId: rowId,
        parentRowHasRoomForMoreColumns: hasRoomForMoreColumns,
        grandParentColumnId: parentColumnId,
        rowCallback,
        columnCallback,
        numberColumnsInGrid
      });
    };
    let results = [];
    results = results.concat(rowCallback({
      row,
      i,
      nodeCounter: nodeCountRef.value,
      rowId,
      parentColumnId,
      depth,
      isFirstRow,
      isLastRow,
      previousRowId,
      nextRowId,
      numChildColumns,
      columnCallback: columnsCallbackForThisRow
    }));
    return results;
  });
  return flatten(result);
};
export const getLayoutIteratorForTree = tree => ({
  rowCallback,
  columnCallback
}) => {
  const root = tree.getRootCell();
  const nodeCountRef = {
    value: 0
  };
  return rowMapper(root.getRows(), {
    parentColumnId: root.getName(),
    parentColumnWidth: root.getWidth(),
    depth: 1,
    nodeCountRef,
    rowCallback,
    columnCallback,
    numberColumnsInGrid: tree.getNumberColumnsInGrid()
  });
};
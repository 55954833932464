import { createSelector } from 'reselect';
export function createUndoableSelectorPOJO(selector, key) {
  return createSelector([selector], result => {
    if (result) {
      return result[key];
    }
    return null;
  });
}
export const createUndoableSelector = (selector, key) => {
  return createSelector([selector], result => {
    if (result) {
      return result.get(key);
    }
    return null;
  });
};
export function createCanUndoSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.past.length > 0;
    }
    return false;
  });
}
export const createCanUndoSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('past').size > 0;
    }
    return false;
  });
};
export function createCanRedoSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.future.length > 0;
    }
    return false;
  });
}
export const createCanRedoSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('future').size > 0;
    }
    return false;
  });
};
export function createLastUndoableActionTypeSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result && result.pastActionTypes) {
      return result.pastActionTypes.at(-1);
    }
    return '';
  });
}
export const createLastUndoableActionTypeSelector = selector => {
  return createSelector([selector], result => {
    if (result && result.has('pastActionTypes')) {
      return result.get('pastActionTypes').last();
    }
    return '';
  });
};
export function createLastUndoneActionTypeSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result && result.futureActionTypes) {
      return result.futureActionTypes.at(-1);
    }
    return '';
  });
}
export const createLastUndoneActionTypeSelector = selector => {
  return createSelector([selector], result => {
    if (result && result.has('futureActionTypes')) {
      return result.get('futureActionTypes').last();
    }
    return '';
  });
};
export function createRedoCountSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.redoCount;
    }
    return 0;
  });
}
export const createRedoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('redoCount');
    }
    return 0;
  });
};
export function createUndoCountSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result) {
      return result.undoCount;
    }
    return 0;
  });
}
export const createUndoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      return result.get('undoCount');
    }
    return 0;
  });
};
export function createUndoRedoCountSelectorPOJO(selector) {
  return createSelector([selector], result => {
    if (result) {
      const redoCount = result.redoCount || 0;
      const undoCount = result.undoCount || 0;
      return undoCount + redoCount;
    }
    return 0;
  });
}
export const createUndoRedoCountSelector = selector => {
  return createSelector([selector], result => {
    if (result) {
      const redoCount = result.get('redoCount') || 0;
      const undoCount = result.get('undoCount') || 0;
      return undoCount + redoCount;
    }
    return 0;
  });
};
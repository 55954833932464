import Immutable from 'immutable';
import { exportForLayoutDataApi, importTreeFromLayoutDataApi
// @ts-expect-error not typed yet
} from 'layout-data-lib/LayoutDataTree/serialize';
import { BODY_ID, WIDGET_SIDEBAR_NAME_BLOCKLIST, WIDGET_SIDEBAR_TYPE_BLOCKLIST } from 'ContentEditorUI/lib/widgetEdit/constants';
import { createSelector } from 'reselect';
import { basicSelector, basicSelectorWithStats, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getModuleIdsByBuiltinType, getBuiltinModuleSchemaByType, getBuiltInTypesByModuleId, getGlobalGroupInfo, getGlobalPartialInfo, getGlobalGroupInfoAsImmutable, getGlobalPartialInfoAsImmutable, getSchemaForModule, getAllModuleSchemasByPath } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getSmartViewCriterionId } from 'ContentEditorUI/redux/selectors/smartViewSelectors';
import { getHasGlobalContentEditorAccess, getIsUngatedForFixedLayoutSections, getIsUngatedForImmerModuleReducer, getIsUngatedForScpModuleInsertion } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getIsDataAuthoringModeActive } from 'ContentEditorUI/redux/selectors/instanceEditingSelectors';
import { createCanRedoSelector, createCanRedoSelectorPOJO, createCanUndoSelector, createCanUndoSelectorPOJO, createLastUndoableActionTypeSelector, createLastUndoableActionTypeSelectorPOJO, createLastUndoneActionTypeSelector, createLastUndoneActionTypeSelectorPOJO, createUndoCountSelector, createUndoCountSelectorPOJO, createUndoRedoCountSelector, createUndoRedoCountSelectorPOJO } from 'ContentEditorUI/redux/selectors/undoRedoSelectors';
import I18n from 'I18n';
import { Map as ImmutableMap, List } from 'immutable';
import { findModuleById, getIconForModule, isCmv2Module, isV1GlobalModule, isCmv2GlobalModule, isCustomCmv2Module, findModuleByIdImmer
// @ts-expect-error not typed yet
} from 'ContentEditorUI/data/moduleUtils';
import { getIsPage, getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { selectInstanceEditingUndoCount, selectInstanceEditingUndoRedoCount, selectInstanceEditingModuleMetaDataAsImmutable } from 'structured-content-lib/redux/selectors/instanceEditSelectors';
import { getUndoableModules, getModuleLists, getSchemaLayoutSectionTrees, getContentLayoutSectionTrees, makeCachedStaticModulesSelector, makeCachedModulesInsideAllFlexColumnsSelector, makeCachedModulesInsideLayoutSectionSelector, makeCachedRootModulesForEachLayoutSectionSelector, getLayoutSectionWidgetsModuleMap, getFlexAreasMetadata, getWidgetsInRichText, getEmbedInfo, makeCachedStaticModulesSelectorImmer, makeCachedModulesInsideAllFlexColumnsSelectorImmer, makeCachedModulesInsideLayoutSectionSelectorImmer, makeCachedRootModulesForEachLayoutSectionSelectorImmer } from './moduleSelectorHelpers';
import { getImmutableOrPlain, isImmutable, hasOwn } from 'ContentEditorUI/utils/dataHelpers';
// @ts-expect-error not typed yet
import EditorConfigSingleton from '../../EditorConfigSingleton';
import { makeHublModuleElementManager } from 'tinymce-plugins/hsmoduleinsertion/utils/hubl';
import { DND_AREA_ID } from 'ContentEditorUI/utils/email/contentTreeUtils';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
// @ts-expect-error not typed yet
import { getContentState } from './baseContentModelSelectors';
// @ts-expect-error not typed yet
import { forEachTree } from 'layout-dnd-utils/layoutTreeIterators';

// @ts-expect-error not typed

import { getIsUngatedForSCPWidgetEditor } from './authSelectors';
import isEmpty from 'hs-lodash/isEmpty';
import get from 'hs-lodash/get';
export { getModuleLists, getSchemaLayoutSectionTrees, getContentLayoutSectionTrees };

// Ugly cast, but otherwise I would have had to add the partial instance editing state to the PartialAuthState
// which would have had a lot more downstream type impacts
const _getIsDataAuthoringModeActive = getIsDataAuthoringModeActive;
// SCP helpers. @TODO: Move to helper file?
const selectIsInstanceEditing = createSelector([_getIsDataAuthoringModeActive, getIsUngatedForSCPWidgetEditor], (isDataAuthoringModeActive, isUngatedForSCPWidgetEditor) => isDataAuthoringModeActive && isUngatedForSCPWidgetEditor);
const maybeAsInstanceEditingSelector = (state, instanceEditingSelector, defaultSelector) => {
  const isInstanceEditing = selectIsInstanceEditing(state);
  return isInstanceEditing ? instanceEditingSelector(state) : defaultSelector(state);
};
export const _getModuleUndoCount = makeGatedSelector(getIsUngatedForImmerModuleReducer, createUndoCountSelector(getUndoableModules.gated), createUndoCountSelectorPOJO(getUndoableModules.ungated));
export const getModuleUndoCount = state => maybeAsInstanceEditingSelector(state, selectInstanceEditingUndoCount, _getModuleUndoCount);
export const _getModuleUndoRedoCount = makeGatedSelector(getIsUngatedForImmerModuleReducer, createUndoRedoCountSelector(getUndoableModules.gated), createUndoRedoCountSelectorPOJO(getUndoableModules.ungated));
export const getModuleUndoRedoCount = state => maybeAsInstanceEditingSelector(state, selectInstanceEditingUndoRedoCount, _getModuleUndoRedoCount);

// The SCP alternatives are handled by the consuming `UndoRedoButtonContainer` component
export const getCanUndoModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createCanUndoSelector(getUndoableModules.gated), createCanUndoSelectorPOJO(getUndoableModules.ungated));
export const getCanRedoModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createCanRedoSelector(getUndoableModules.gated), createCanRedoSelectorPOJO(getUndoableModules.ungated));
export const getLastUndoableActionType = makeGatedSelector(getIsUngatedForImmerModuleReducer, createLastUndoableActionTypeSelector(getUndoableModules.gated), createLastUndoableActionTypeSelectorPOJO(getUndoableModules.ungated));
export const getLastUndoneActionType = makeGatedSelector(getIsUngatedForImmerModuleReducer, createLastUndoneActionTypeSelector(getUndoableModules.gated), createLastUndoneActionTypeSelectorPOJO(getUndoableModules.ungated));
const getContentCssImmer = createSelector(getModuleLists.ungated, moduleLists => moduleLists.contentCss);
const getContentCss = createSelector(getModuleLists.gated, moduleLists => moduleLists.get('contentCss'));
export const getAllEditableStaticModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, makeCachedStaticModulesSelector(), makeCachedStaticModulesSelectorImmer());
const getAllFlexColumnModulesAndContainer = makeGatedSelector(getIsUngatedForImmerModuleReducer, makeCachedModulesInsideAllFlexColumnsSelector(), makeCachedModulesInsideAllFlexColumnsSelectorImmer());
export const getModulesInsideLayoutSections = makeGatedSelector(getIsUngatedForImmerModuleReducer, makeCachedModulesInsideLayoutSectionSelector(), makeCachedModulesInsideLayoutSectionSelectorImmer());

// Fake body widget used to set body styles
export const getFakeBodyModuleCss = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getContentCss, contentCss => contentCss.get(BODY_ID) || ImmutableMap()), createSelector(getContentCssImmer, contentCss => contentCss[BODY_ID] || {}));
export const getHasAnyFakeBodyModuleCss = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getFakeBodyModuleCss.gated, bodyCss => !!bodyCss && bodyCss.size > 0), createSelector(getFakeBodyModuleCss.ungated, bodyCss => !!bodyCss && !isEmpty(bodyCss)));
export const getFakeBodyModule = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getFakeBodyModuleCss.gated, bodyCss => {
  return ImmutableMap({
    type: 'fake',
    selector: 'body',
    id: BODY_ID,
    name: BODY_ID,
    label: 'Whole Page (<body>)',
    css: bodyCss
  });
}), createSelector(getFakeBodyModuleCss.ungated, bodyCss => {
  return {
    type: 'fake',
    selector: 'body',
    id: BODY_ID,
    name: BODY_ID,
    label: 'Whole Page (<body>)',
    css: bodyCss
  };
}));
const getRootModulesForEachLayoutSectionImmer = makeCachedRootModulesForEachLayoutSectionSelectorImmer();
const getRootModulesForEachLayoutSection = makeCachedRootModulesForEachLayoutSectionSelector();
const getRootModulesForEachLayoutSectionAsMapImmer = createSelector(getRootModulesForEachLayoutSectionImmer, rootModulesForEachLayoutSection => {
  return Object.fromEntries(rootModulesForEachLayoutSection.map(rootModule => [rootModule.name, rootModule]));
});
const getRootModulesForEachLayoutSectionAsMap = createSelector(getRootModulesForEachLayoutSection, rootModulesForEachLayoutSection => {
  // @ts-expect-error should not be using new here
  return new ImmutableMap(rootModulesForEachLayoutSection.map(rootModule => [rootModule.get('name'), rootModule]));
});
const __getFakeModules = createSelector([getModuleLists.gated], moduleLists => moduleLists.get('fakeModules'));
export const getFakeModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([__getFakeModules], fakeModules => fakeModules.toObject()), createSelector([getModuleLists.ungated], moduleLists => moduleLists.fakeModules));

// Exclude the container wrapper that "surrounds" the whole flex column
export const getAllFlexColumnModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getAllFlexColumnModulesAndContainer.gated, allFlexColumnModulesAndContainer => {
  return Object.fromEntries(Object.entries(allFlexColumnModulesAndContainer).filter(([__key, module]) => module.get('type') !== 'container'));
}), createSelector(getAllFlexColumnModulesAndContainer.ungated, allFlexColumnModulesAndContainer => {
  return Object.entries(allFlexColumnModulesAndContainer).reduce((acc, [id, module]) => {
    if (module.type !== 'container') {
      acc[id] = module;
    }
    return acc;
  }, {});
}));
export const getAllStaticAndFlexColumnModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getAllEditableStaticModules.gated, getAllFlexColumnModules.gated, (allStaticModules, allFlexColumnModules) => Object.assign({}, allStaticModules, allFlexColumnModules)), createSelector(getAllEditableStaticModules.ungated, getAllFlexColumnModules.ungated, (allStaticModules, allFlexColumnModules) => Object.assign({}, allStaticModules, allFlexColumnModules)));
export const isFakeBodyModuleName = name => name === 'post_body';
export const getFakePostBodyModule = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getFakeModules.gated], fakeModules => Object.values(fakeModules).find(module => isFakeBodyModuleName(module.get('name')))), createSelector([getFakeModules.ungated], fakeModules => Object.values(fakeModules).find(module => isFakeBodyModuleName(module.name))));
export const getFakePostBodyHTML = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getFakePostBodyModule.gated], fakePostBodyModule => {
  if (fakePostBodyModule === undefined) {
    return null;
  }
  return fakePostBodyModule.getIn(['body', 'html']);
}), createSelector([getFakePostBodyModule.ungated], fakePostBodyModule => {
  var _fakePostBodyModule$b;
  if (fakePostBodyModule === undefined) {
    return null;
  }
  return (_fakePostBodyModule$b = fakePostBodyModule.body) === null || _fakePostBodyModule$b === void 0 ? void 0 : _fakePostBodyModule$b.html;
}));
export const getModulesFromPostBody = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getFakePostBodyModule.gated, getAllModuleSchemasByPath, getWidgetsInRichText.gated, (postBodyModule, allModuleSchemasByPath, widgetsInRichText) => {
  if (!postBodyModule) {
    return [];
  }
  const postBodyHtml = postBodyModule.getIn(['body', 'html'], '');
  const hublModuleElementManager = makeHublModuleElementManager(postBodyHtml);
  const postBodyModuleNames = new Set();
  const postBodyModules = hublModuleElementManager.getAll().map(module => {
    const moduleName = module && module.info && module.info.name;
    if (postBodyModuleNames.has(moduleName)) {
      const message = `[ERROR] Duplicate module found in post body`;
      captureMessage(message, {
        extra: {
          moduleName
        }
      });
      console.error(message, moduleName);
    }
    postBodyModuleNames.add(moduleName);
    // @ts-expect-error still want to capture undefined names
    return widgetsInRichText.get(moduleName);
  }).filter(module => {
    if (!module) {
      const message = "[ERROR] Couldn't find module in widgetInRichText slice";
      captureMessage(message);
      console.error(message);
    }
    return Boolean(module);
  });
  return postBodyModules;
}), createSelector(getFakePostBodyModule.ungated, getAllModuleSchemasByPath, getWidgetsInRichText.ungated, (postBodyModule, allModuleSchemasByPath, widgetsInRichText) => {
  if (!postBodyModule) {
    return [];
  }
  const postBodyHtml = get(postBodyModule, ['body', 'html'], '');
  const hublModuleElementManager = makeHublModuleElementManager(postBodyHtml);
  const postBodyModuleNames = new Set();
  const postBodyModules = hublModuleElementManager.getAll().map(module => {
    const moduleName = module && module.info && module.info.name;
    if (postBodyModuleNames.has(moduleName)) {
      const message = `[ERROR] Duplicate module found in post body`;
      captureMessage(message, {
        extra: {
          moduleName
        }
      });
      console.error(message, moduleName);
    }
    postBodyModuleNames.add(moduleName);
    // Doing a cast here so that downstream we're working with just a Module
    // But widgetsInRichText holds ContentEditWidgets
    return moduleName ? widgetsInRichText[moduleName] : undefined;
  }).filter(module => {
    if (!module) {
      const message = "[ERROR] Couldn't find module in widgetInRichText slice";
      captureMessage(message);
      console.error(message);
    }
    return Boolean(module);
  });
  return postBodyModules;
}));
export const getModulesByNameFromPostBody = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModulesFromPostBody.gated, postBodyModulesByName => Object.fromEntries(postBodyModulesByName.map(module => [module.get('name'), module]))), createSelector(getModulesFromPostBody.ungated, postBodyModulesByName => postBodyModulesByName.reduce((acc, module) => {
  acc[module.name] = module;
  return acc;
}, {})));
export const selectIsPostBodyModule = (state, {
  moduleName
}) => {
  const modules = getModulesByNameFromPostBody(state);
  if (isImmutable(modules)) {
    return modules.has(moduleName);
  } else {
    return Object.hasOwn(modules, moduleName);
  }
};
const BLOG_POST_BODY_MODULES = 'blog_post_body_modules';
export const getIsBlogPostBodyModuleGroup = id => id === BLOG_POST_BODY_MODULES;
export const getPostBodyModulesAsLayoutTree = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getFakePostBodyModule.gated, getModulesFromPostBody.gated, (fakePostBodyModule, postBodyModules) => {
  if (!fakePostBodyModule) {
    return null;
  }
  const treeNodeDefaultValues = {
    styles: {},
    x: 0,
    w: 12
  };
  const makeTreeNodeFromModule = (module, extraData = {}, extraParams = {}) => Object.assign({}, treeNodeDefaultValues, module.toJS(), extraData, {
    params: Object.assign({}, module.get('body').toJS(), extraParams)
  });
  // marking this as any for now and I'll fix it in the immer version
  const makeTreeRows = rows => rows.map(
  // @ts-expect-error will fix this in the immer version
  ({
    module,
    extraData,
    extraParams,
    rows: childRows = []
  }) => ({
    0: Object.assign({}, makeTreeNodeFromModule(module, extraData, extraParams), {
      rows: makeTreeRows(childRows)
    })
  }));
  const moduleRowData = [{
    module: fakePostBodyModule,
    extraData: {
      type: 'cell'
    },
    extraParams: {
      isModuleParent: true
    },
    rows: [...postBodyModules.map(module => ({
      module
    }))]
  }];
  const moduleTreeData = {
    label: I18n.text('widgetList.blogPostContent'),
    name: BLOG_POST_BODY_MODULES,
    id: BLOG_POST_BODY_MODULES,
    rows: makeTreeRows(moduleRowData),
    rowMetaData: [{
      skipNode: true
    }]
  };
  return importTreeFromLayoutDataApi(moduleTreeData);
}), createSelector(getFakePostBodyModule.ungated, getModulesFromPostBody.ungated, (fakePostBodyModule, postBodyModules) => {
  if (!fakePostBodyModule) {
    return null;
  }
  const treeNodeDefaultValues = {
    styles: {},
    x: 0,
    w: 12
  };
  const makeTreeNodeFromModule = (module, extraData = {}, extraParams = {}) => Object.assign({}, treeNodeDefaultValues, module, extraData, {
    params: Object.assign({}, module.body, extraParams)
  });
  const makeTreeRows = rows => rows.map(({
    module,
    extraData,
    extraParams,
    rows: childRows = []
  }) => ({
    0: Object.assign({}, makeTreeNodeFromModule(module, extraData, extraParams), {
      rows: makeTreeRows(childRows)
    })
  }));
  const moduleRowData = [{
    module: fakePostBodyModule,
    extraData: {
      type: 'cell'
    },
    extraParams: {
      isModuleParent: true
    },
    rows: [...postBodyModules.map(module => ({
      module
    }))]
  }];
  const moduleTreeData = {
    label: I18n.text('widgetList.blogPostContent'),
    name: BLOG_POST_BODY_MODULES,
    id: BLOG_POST_BODY_MODULES,
    rows: makeTreeRows(moduleRowData),
    rowMetaData: [{
      skipNode: true
    }]
  };
  return importTreeFromLayoutDataApi(moduleTreeData);
}));
export const getModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getAllEditableStaticModules.gated, getAllFlexColumnModulesAndContainer.gated, getModulesInsideLayoutSections.gated, getRootModulesForEachLayoutSectionAsMap, getFakeModules.gated, getFakeBodyModule.gated, getModulesByNameFromPostBody.gated, getWidgetsInRichText.gated, (allStaticModules, allFlexColumnModulesAndContainer, modulesInsideLayoutSections, rootModulesForEachLayoutSectionAsMap, fakeModules, fakeBodyModule, postBodyModules, widgetsInRichText) => {
  const modules = ImmutableMap(allStaticModules)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .merge(modulesInsideLayoutSections).merge(rootModulesForEachLayoutSectionAsMap)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .merge(allFlexColumnModulesAndContainer)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .merge(fakeModules)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .merge(postBodyModules)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .merge(widgetsInRichText)
  // @ts-expect-error Technically these types don't overlap but that doesn't matter to us
  .set(BODY_ID, fakeBodyModule);
  return modules;
}),
// TODO: Figure out if this should just return a Record<string, Module> or stick with the union it returns
createSelector(getAllEditableStaticModules.ungated, getAllFlexColumnModulesAndContainer.ungated, getModulesInsideLayoutSections.ungated, getRootModulesForEachLayoutSectionAsMapImmer, getFakeModules.ungated, getFakeBodyModule.ungated, getModulesByNameFromPostBody.ungated, (allStaticModules, allFlexColumnModulesAndContainer, modulesInsideLayoutSections, rootModulesForEachLayoutSectionAsMap, fakeModules, fakeBodyModule, postBodyModules) => {
  const modules = Object.assign({}, allStaticModules, modulesInsideLayoutSections, rootModulesForEachLayoutSectionAsMap, allFlexColumnModulesAndContainer, fakeModules, postBodyModules);
  // @ts-expect-error TODO
  modules[BODY_ID] = fakeBodyModule;
  return modules;
}));
const _getModuleMetaData = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModuleLists.gated], moduleLists => moduleLists.get('moduleMetaData')), createSelector([getModuleLists.ungated], moduleLists => moduleLists.moduleMetaData));
export const getModuleMetaData = state => maybeAsInstanceEditingSelector(state, selectInstanceEditingModuleMetaDataAsImmutable, _getModuleMetaData);
const __getUneditableModules = createSelector([getModuleLists.gated], moduleLists => moduleLists.get('uneditableWidgets'));
export const getUneditableModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([__getUneditableModules], uneditableWidgets => uneditableWidgets.toObject()), createSelector([getModuleLists.ungated], moduleLists => moduleLists.uneditableWidgets));
export const getAllModuleKindsHelper = createSelector(getModules, getUneditableModules, getFakeModules, (modules, uneditableModules, fakeModules) => ({
  modules,
  uneditableModules,
  fakeModules
}));
const isFakePostTitleName = name => name === 'name';
export const getFakePostTitleModule = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getFakeModules.gated], fakeModules => Object.values(fakeModules).find(module => isFakePostTitleName(module.get('name')))), createSelector([getFakeModules.ungated], fakeModules => Object.values(fakeModules).find(module => isFakePostTitleName(module.name))));
export const getHasAnyFlexColumns = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModuleLists.gated, moduleLists => moduleLists.get('schemaWidgetContainers').size > 0), createSelector(getModuleLists.ungated, moduleLists => Object.keys(moduleLists.schemaWidgetContainers).length > 0));
export const getHasModulesInFlexColumn = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats(state => {
  const moduleLists = getModuleLists.gated(state);
  return moduleLists.get('widgetContainers').filter(container => {
    return container.size > 0;
  }).size > 0;
}), basicSelectorWithStats(state => {
  const moduleLists = getModuleLists.ungated(state);
  return Object.values(moduleLists.widgetContainers).filter(container => {
    return Object.keys(container).length > 0;
  }).length > 0;
}));
export const getIsModuleOverrideable = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const module = getModules.gated(state).get(id);
  if (module) {
    return module.get('overrideable');
  }
  return false;
}), basicSelectorWithStats((state, id) => {
  const module = getModules.ungated(state)[id];
  if (module) {
    // @ts-expect-error TODO: consolidate module types
    return module.overrideable;
  }
  return false;
}));
export const getAllNonFakeBodyModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, modules => modules.delete(BODY_ID).toObject()), createSelector(getModules.ungated, modules => {
  const newModules = Object.assign({}, modules);
  delete newModules.BODY_ID;
  return newModules;
}));
export const getModuleById = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const modules = getModules.gated(state);
  return findModuleById(modules, id);
}), basicSelectorWithStats((state, id) => {
  const modules = getModules.ungated(state);
  return findModuleByIdImmer(modules, id);
}));
export const getRichTextModuleById = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const modules = getWidgetsInRichText.gated(state);
  return findModuleById(modules, id);
}), basicSelectorWithStats((state, id) => {
  const modules = getWidgetsInRichText.ungated(state);
  return findModuleByIdImmer(modules, id);
}));
export const getIsModuleInLayoutSection = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (!module) {
    return false;
  }
  return isImmutable(module) ?
  // @ts-expect-error treating modules as discriminant unions
  module.has('layout_section_id') : Object.hasOwn(module, 'layout_section_id');
});
export const getIsLayoutSectionModuleIsIn = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (!module) {
    return undefined;
  }
  return isImmutable(module) ?
  // @ts-expect-error treating modules as discriminant unions
  module.get('layout_section_id') :
  // @ts-expect-error treating modules as discriminant unions
  module.layout_section_id;
});
export const getModuleOrUneditableOrFakeModuleByIdHelperImmer = (allModuleKinds, id) => {
  const {
    modules,
    uneditableModules,
    fakeModules
  } = allModuleKinds;
  return findModuleByIdImmer(modules, id) || uneditableModules[id] || fakeModules[id];
};
export const getModuleOrUneditableOrFakeModuleByIdHelper = (allModuleKinds, id) => {
  const {
    modules,
    uneditableModules,
    fakeModules
  } = allModuleKinds;
  return findModuleById(modules, id) || uneditableModules[id] || fakeModules[id];
};
export const getAllModulesSmartRulesDefinitionIds = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, getUneditableModules.gated, (modules, uneditableModules) => {
  const definitionIds = [];
  modules.forEach(module => {
    const definitionId = module.get('definition_id');
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  Object.values(uneditableModules).forEach(module => {
    const definitionId = module.get('definition_id');
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  return definitionIds;
}), createSelector(getModules.ungated, getUneditableModules.ungated, (modules, uneditableModules) => {
  const definitionIds = [];
  Object.values(modules).forEach(module => {
    // @ts-expect-error Finalize central module type
    const definitionId = module.definition_id;
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  Object.values(uneditableModules).forEach(module => {
    const definitionId = module.definition_id;
    if (definitionId) {
      definitionIds.push(definitionId);
    }
  });
  return definitionIds;
}));
export const getModuleMetaDataById = basicSelectorWithStats((state, id) => {
  const moduleMetaData = getModuleMetaData(state);
  return isImmutable(moduleMetaData) ? moduleMetaData.get(id) : moduleMetaData[id];
});
export const getUneditableModuleById = basicSelectorWithStats((state, id) => {
  const uneditableModules = getUneditableModules(state);
  return isImmutable(uneditableModules) ? uneditableModules.get(id) : uneditableModules[id];
});
export const getGlobalGroupByPath = basicSelectorWithStats((state, path) => {
  const globalGroups = getGlobalGroupInfo(state);
  return globalGroups[path];
});
const getGlobalGroupByPathAsImmutable = basicSelector((state, path) => {
  const globalGroups = getGlobalGroupInfoAsImmutable(state);
  return globalGroups && globalGroups.get(path);
});
export const getGlobalPartialByPath = basicSelectorWithStats((state, path) => {
  const globalPartials = getGlobalPartialInfo(state);
  return globalPartials[path];
});
const getGlobalPartialByPathAsImmutable = basicSelector((state, path) => {
  const globalPartials = getGlobalPartialInfoAsImmutable(state);
  return globalPartials && globalPartials.get(path);
});
export const getGlobalGroupOrPartialByPath = basicSelectorWithStats((state, path) => {
  return getGlobalGroupByPath(state, path) || getGlobalPartialByPath(state, path);
});
export const getFakeModuleById = basicSelectorWithStats((state, id) => {
  const fakeModules = getFakeModules(state);
  return isImmutable(fakeModules) ? fakeModules.get(id) : fakeModules[id];
});
export const getModuleOrFakeModuleById = basicSelectorWithStats((state, id) => {
  return getModuleById(state, id) || getFakeModuleById(state, id);
});
export const getModuleOrUneditableModuleById = basicSelectorWithStats((state, id) => {
  return getModuleById(state, id) || getUneditableModuleById(state, id);
});
export const getAnyModuleById = basicSelectorWithStats((state, id) => {
  const isUngatedForImmerModuleReducer = getIsUngatedForImmerModuleReducer(state);
  const getGlobalGroupByPathFunc = isUngatedForImmerModuleReducer ? getGlobalGroupByPath : getGlobalGroupByPathAsImmutable;
  const getGlobalPartialByPathFunc = isUngatedForImmerModuleReducer ? getGlobalPartialByPath : getGlobalPartialByPathAsImmutable;
  return getModuleById(state, id) || getFakeModuleById(state, id) || getUneditableModuleById(state, id) || getGlobalGroupByPathFunc(state, id) || getGlobalPartialByPathFunc(state, id);
});
export const makeGetAnyModuleById = moduleId => makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, getFakeModules.gated, getUneditableModules.gated, (modules, fakeModules, uneditableModules) => findModuleById(modules, moduleId) || fakeModules[moduleId] || uneditableModules[moduleId]), createSelector(getModules.ungated, getFakeModules.ungated, getUneditableModules.ungated, (modules, fakeModules, uneditableModules) => findModuleByIdImmer(modules, moduleId) || fakeModules[moduleId] || uneditableModules[moduleId]));
export const getIsCMv2Module = basicSelectorWithStats((state, id) => {
  const module = getAnyModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    return isCmv2Module(module, schema);
  }
  return false;
});

// Intentionally choosing this to be one of the few situations we support passing in
// Immutable or plain JS objects
export const getDefaultTypeHelper = (module, schema, builtInTypesByModuleId) => {
  if (schema && getImmutableOrPlain(schema, 'default')) {
    const moduleId = getImmutableOrPlain(module, 'module_id');
    return builtInTypesByModuleId[moduleId] || getImmutableOrPlain(module, 'type');
  }
  return getImmutableOrPlain(module, 'type');
};
export const getTypeByModuleId = (moduleId, builtInTypesByModuleId) => {
  return builtInTypesByModuleId[moduleId] || 'custom';
};
export const getDefaultType = basicSelectorWithStats((state, id) => {
  const module = getModuleOrUneditableModuleById(state, id);
  const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
  if (module) {
    const schema = getSchemaForModule(state, module);
    // @ts-expect-error still figuring out how to handle a central module type
    return getDefaultTypeHelper(module, schema, builtInTypesByModuleId);
  }
  return null;
});
export const getDisplayName = basicSelectorWithStats((state, idOrPath) => {
  const module = getAnyModuleById(state, idOrPath);
  if (module) {
    const type = getDefaultType(state, idOrPath);
    const schema = getSchemaForModule(state, module);
    let moduleLabel = getImmutableOrPlain(module, 'label');
    if (moduleLabel && schema && schema.originalDisplayName && moduleLabel === schema.originalDisplayName) {
      moduleLabel = schema.displayName;
    }
    if (getIsCMv2Module(state, idOrPath) && schema && !schema.global) {
      return moduleLabel || schema.displayName;
    } else if (schema && (type === 'module' || type === 'custom_widget' || type === 'global_widget' || type === 'js_module')) {
      return schema.displayName;
    }
    return moduleLabel || I18n.text(`contentmodules.widgets.${getImmutableOrPlain(module, 'type')}.label`);
  }
  return null;
});
export const getIsDefaultModule = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    if (schema) {
      return schema.default;
    }
  }
  return false;
});
const moduleHasAnyStylesHelper = module => {
  const css = !!module && module.get('css');
  return css && css instanceof Immutable.Map && module.get('css').size > 0;
};
const moduleHasAnyStylesHelperImmer = module => {
  const css = !!module && module.css;
  return css && typeof css === 'object' && Object.keys(css).length > 0;
};
export const getHasAnyRegularNonLayoutModuleStyles = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const module = getModuleById.gated(state, id);
  return moduleHasAnyStylesHelper(module);
}), basicSelectorWithStats((state, id) => {
  const module = getModuleById.ungated(state, id);
  // @ts-expect-error TODO: Finalize central module type
  return moduleHasAnyStylesHelperImmer(module);
}));
export const getAllV2ModuleDefinitionIdsWithStylesOnPage = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, allModules => {
  return new Set(allModules.valueSeq().map(module => {
    if (moduleHasAnyStylesHelper(module)) {
      return module.get('module_id');
    }
    return undefined;
  })
  // Filter out undefined
  .filter(x => !!x).toArray());
}), createSelector(getModules.ungated, allModules => {
  return new Set(Object.values(allModules).map(module => {
    // @ts-expect-error TODO: Finalize central module type
    if (moduleHasAnyStylesHelperImmer(module)) {
      // @ts-expect-error TODO: Finalize central module type
      return module.module_id;
    }
    return undefined;
  })
  // Filter out undefined
  .filter(x => !!x));
}));
export const getIsGlobalModule = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  const moduleSpec = !!module && getSchemaForModule(state, module);
  return isV1GlobalModule(module) || moduleSpec && isCmv2GlobalModule(module, moduleSpec);
});
export const getGlobalModuleNames = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats(state => {
  const modules = getModules.gated(state);
  const globalModuleNames = [];
  modules.forEach(module => {
    const moduleName = module.get('name');
    if (getIsGlobalModule(state, moduleName)) {
      globalModuleNames.push(moduleName);
    }
  });
  return globalModuleNames;
}), basicSelectorWithStats(state => {
  const modules = getModules.ungated(state);
  const globalModuleNames = [];
  Object.values(modules).forEach(module => {
    const moduleName = module.name;
    if (getIsGlobalModule(state, moduleName)) {
      globalModuleNames.push(moduleName);
    }
  });
  return globalModuleNames;
}));
export const getIsGlobalModuleGroupOrPartial = basicSelectorWithStats((state, idOrPath) => {
  const isGlobalModule = getIsGlobalModule(state, idOrPath);
  const isGroupOrPartial = !!getGlobalGroupOrPartialByPath(state, idOrPath);
  return isGlobalModule || isGroupOrPartial;
});
export const getIsEditableGlobalContent = basicSelectorWithStats((state, idOrPath) => {
  const module = getModuleById(state, idOrPath);
  const hasGlobalContentEditor = getHasGlobalContentEditorAccess(state);
  if (module) {
    const isGlobalModule = getIsGlobalModule(state, idOrPath);
    return hasGlobalContentEditor && isGlobalModule;
  }
  const globalGroupOrPartial = getGlobalGroupOrPartialByPath(state, idOrPath);
  if (globalGroupOrPartial) {
    return hasGlobalContentEditor;
  }
  return false;
});
export const getIsFakeBodyOrTitleModule = basicSelectorWithStats((state, id) => {
  const module = getFakeModuleById(state, id);
  if (module) {
    const name = getImmutableOrPlain(module, 'name');
    return isFakeBodyModuleName(name) || isFakePostTitleName(name);
  }
  return false;
});
export const getIsModuleEditable = basicSelectorWithStats((state, id) => getIsFakeBodyOrTitleModule(state, id) || getIsModuleOverrideable(state, id) && !getIsGlobalModuleGroupOrPartial(state, id) || getIsEditableGlobalContent(state, id));
export const getIsCustomCmv2Module = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  if (module) {
    const schema = getSchemaForModule(state, module);
    return isCustomCmv2Module(schema);
  }
  return false;
});
export const getIsEmailBodyModule = basicSelectorWithStats((state, id) => {
  if (getIsCMv2Module(state, id)) {
    const module = getModuleById(state, id);
    const builtInTypesByModuleId = getBuiltInTypesByModuleId(state);
    return builtInTypesByModuleId[getImmutableOrPlain(module, 'module_id')] === 'email_body';
  }
  const contentState = getContentState(state);
  return !(contentState.includes('BLOG') || contentState.includes('RSS')) && id === 'hs_email_body';
});
export const getContainers = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, modules => modules.filter(module => module.get('type') === 'container').toObject()), createSelector(getModules.ungated, modules => Object.fromEntries(Object.entries(modules).filter(([__name, module]) => module.type === 'container'))));
export const getHasContainers = createSelector(getContainers, containers => {
  if (!containers) {
    return false;
  }
  return isImmutable(containers) ? containers.count() > 0 : Object.keys(containers).length > 0;
});
export const getModulesInContainers = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, modules => modules.filter(module =>
// @ts-expect-error will be fixed in immer version with better module types
module.has('containerId') && !module.has('layout_section_id')).toObject()), createSelector(getModules.ungated, modules => Object.fromEntries(Object.entries(modules).filter(([__name, module]) => Object.hasOwn(module, 'containerId') && !Object.hasOwn(module, 'layout_section_id')))));
export const getHiddenModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModules.gated, modules => modules.filter(module => module.get('export_to_template_context') === true)
// @ts-expect-error We treat modules as a discriminant union here
.sortBy(module => module.get('order'))), createSelector(getModules.ungated, modules => Object.fromEntries(Object.entries(modules).filter(
// @ts-expect-error We treat modules as a discriminant union here
([__name, module]) => module.export_to_template_context === true).sort(([__nameA, moduleA], [__nameb, moduleB]) =>
// @ts-expect-error We treat modules as a discriminant union here
moduleA.order - moduleB.order))));
export const getUneditableModulesVisibleInSidebar = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getUneditableModules.gated, getModuleIdsByBuiltinType], (uneditableModules, moduleIdsByBuiltinType) => {
  const blogContentModuleId = moduleIdsByBuiltinType.blog_content;
  return Object.fromEntries(Object.entries(uneditableModules).filter(([__name, module]) => module.get('module_id') !== blogContentModuleId));
}), createSelector([getUneditableModules.ungated, getModuleIdsByBuiltinType], (uneditableModules, moduleIdsByBuiltinType) => {
  const blogContentModuleId = moduleIdsByBuiltinType.blog_content;
  return Object.fromEntries(Object.entries(uneditableModules).filter(([__name, module]) => module.module_id !== blogContentModuleId));
}));
const getGlobalGroupsForSidebar = createSelector([getUneditableModules.gated,
// Since the sidebar uses modules and global references interchangeably, make sure to get
// global info as an immutable Map
getGlobalGroupInfoAsImmutable, getGlobalPartialInfoAsImmutable], (uneditableModules, globalGroupInfoMap, globalPartialInfoMap) => {
  let groupInfo = globalGroupInfoMap.concat(globalPartialInfoMap);
  if (groupInfo) {
    Object.values(uneditableModules).forEach(module => {
      const isPartial = module.has('global_partial_path');
      if (module.has('global_group_path') || isPartial) {
        const type = isPartial ? 'global_partial' : 'global_group';
        const path = `${module.get(`${type}_path`)}`;
        if (groupInfo.has(path)) {
          const order = groupInfo.get('order');
          if (!order || module.get('order') < order) {
            groupInfo = groupInfo.mergeIn([path], Immutable.Map({
              line_number: module.get('line_number'),
              order: module.get('order'),
              path,
              type
            }));
          }
        }
      }
    });
    return groupInfo;
  }
  return Immutable.Map();
});
const getGlobalGroupsForSidebarImmer = createSelector([getUneditableModules.ungated,
// Since the sidebar uses modules and global references interchangeably, make sure to get
// global info as an immutable Map
getGlobalGroupInfo, getGlobalPartialInfo], (uneditableModules, globalGroupInfoMap, globalPartialInfoMap) => {
  const groupInfo = Object.assign({}, globalGroupInfoMap, globalPartialInfoMap);
  if (groupInfo) {
    Object.values(uneditableModules).forEach(module => {
      const isPartial = module.global_partial_path;
      if (module.global_group_path != null || isPartial) {
        const type = isPartial ? 'global_partial' : 'global_group';
        const path = `${module[`${type}_path`]}`;
        if (Object.hasOwn(groupInfo, path)) {
          const order = groupInfo.order;
          // @ts-expect-error This might be a logic bug
          if (!order || module.order < order) {
            const groupInfoAtPath = groupInfo[path];
            groupInfo[path] = Object.assign({}, groupInfoAtPath, {
              // @ts-expect-error line_number does not exist on GlobalGroupInfo or GlobalPartialInfo
              line_number: module.line_number,
              order: module.order,
              path,
              type
            });
          }
        }
      }
    });
    return groupInfo;
  }
  return {};
});
const getModulesVisibleInSidebar = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModules.gated, getUneditableModulesVisibleInSidebar.gated, getGlobalGroupsForSidebar], (modules, uneditableModulesVisibleInSidebar, globalGroupsForSidebar) => {
  return modules.filter(module => WIDGET_SIDEBAR_TYPE_BLOCKLIST.indexOf(module.getIn(['type'])) === -1 && WIDGET_SIDEBAR_NAME_BLOCKLIST.indexOf(module.getIn(['name'])) === -1).concat(uneditableModulesVisibleInSidebar).concat(globalGroupsForSidebar);
}), createSelector([getModules.ungated, getUneditableModulesVisibleInSidebar.ungated, getGlobalGroupsForSidebarImmer], (modules, uneditableModulesVisibleInSidebar, globalGroupsForSidebar) => {
  const filteredModules = Object.fromEntries(Object.entries(modules).filter(([__key, module]) =>
  // @ts-expect-error TODO: Finalize central module type
  WIDGET_SIDEBAR_TYPE_BLOCKLIST.indexOf(module.type) === -1 &&
  // @ts-expect-error TODO: Finalize central module type
  WIDGET_SIDEBAR_NAME_BLOCKLIST.indexOf(module.name) === -1));
  return Object.assign(filteredModules, uneditableModulesVisibleInSidebar, globalGroupsForSidebar);
}));
export const getSortedStaticModulesAndContainers = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModulesVisibleInSidebar.gated, getModulesByNameFromPostBody.gated], (modulesVisibleInSidebar, postBodyModules) => {
  return modulesVisibleInSidebar.filter(module => module.has('line_number') &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.has('containerId') &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.has('layout_section_id') &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.has('global_group_path') &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.has('global_partial_path') && module.get('export_to_template_context') !== true && !hasOwn(postBodyModules, module.get('name')) &&
  // @ts-expect-error we treat modules as exclusive unions here
  module.get('path') !== '@hubspot/blog_audio')
  // @ts-expect-error we treat modules as exclusive unions here
  .sortBy(module => module.get('order'));
}), createSelector([getModulesVisibleInSidebar.ungated, getModulesByNameFromPostBody.ungated], (modulesVisibleInSidebar, postBodyModules) => {
  const moduleEntries = Object.entries(modulesVisibleInSidebar).filter(([__name, module]) => module.line_number &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.containerId &&
  // @ts-expect-error we treat modules as exclusive unions here
  !module.layout_section_id && !module.global_group_path && !module.global_partial_path && module.export_to_template_context !== true && !Object.hasOwn(postBodyModules, module.name) && module.path !== '@hubspot/blog_audio').sort(([__nameA, moduleA], [__nameB, moduleB]) => moduleA.order - moduleB.order);
  return Object.fromEntries(moduleEntries);
}));
export const getModulesInContainerHelper = (modules, containerId) => {
  const moduleEntries = Object.entries(modules).filter(([__name, module]) => module.get('containerId') === containerId);
  const filteredModulesAsImmutable = ImmutableMap(moduleEntries);
  return filteredModulesAsImmutable.sortBy(module => module.get('order'));
};

// TODO CR-immer:  Use this correctly downstream after all moduleSelectors are migrated
export const getModulesInContainerHelperImmer = (modules, containerId) => {
  const moduleEntries = Object.entries(modules).filter(([__name, module]) => module.containerId === containerId).sort(([__nameA, moduleA], [__nameB, moduleB]) => moduleA.order - moduleB.order);
  return Object.fromEntries(moduleEntries);
};
export const getModulesInLayoutSectionHelper = (modules, layoutSectionTree) => {
  return List(layoutSectionTree.allModules().map(cell => modules.get(cell.getName())));
};

// TODO CR-immer: - Use this correctly downstream after all moduleSelectors are migrated
export const getModulesInLayoutSectionHelperImmer = (modules, layoutSectionTree) => {
  return layoutSectionTree.allModules().map(cell => modules[cell.getName()]);
};
export const getModulesInAnyLayoutSection = createSelector(getModules, modules => {
  // @ts-expect-error we treat modules as exclusive unions here
  return modules.filter(module => module.has('layout_section_id'));
});

// All global v1 modules not in flex columns or D&D areas
const getAllStaticGlobalV1Modules = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getUneditableModules.gated, uneditableModules => {
  return Object.fromEntries(Object.entries(uneditableModules).filter(([__name, module]) => isV1GlobalModule(module)));
}), createSelector(getUneditableModules.ungated, uneditableModules => {
  return Object.fromEntries(Object.entries(uneditableModules).filter(([__name, module]) => isV1GlobalModule(module)));
}));
const getLayoutSectionTrees = createSelector(getModuleLists.gated, moduleLists => moduleLists.get('layoutSectionTrees'));
const getLayoutSectionTreesImmer = createSelector(getModuleLists.ungated, moduleLists => moduleLists.layoutSectionTrees);
const getAllStaticSectionsFromTrees = createSelector([getLayoutSectionTrees], layoutSectionTrees => {
  let staticSection = [];
  layoutSectionTrees.forEach(tree => {
    const currentTreesStaticSections = tree
    // need to go deeper than the root, as a static section is top level
    .getRootCell().getRows().filter(row => row.isStaticSection());
    staticSection = staticSection.concat(currentTreesStaticSections);
  });
  return staticSection;
});
const getAllStaticSectionsFromTreesImmer = createSelector([getLayoutSectionTreesImmer], layoutSectionTrees => {
  let staticSection = [];
  Object.values(layoutSectionTrees).forEach(tree => {
    const currentTreesStaticSections = tree
    // need to go deeper than the root, as a static section is top level
    .getRootCell().getRows().filter(row => row.isStaticSection());
    staticSection = staticSection.concat(currentTreesStaticSections);
  });
  return staticSection;
});
const getStaticSectionModules = createSelector([getLayoutSectionWidgetsModuleMap.gated, getAllStaticSectionsFromTrees], (layoutSectionWidgetsModuleMap, allStaticSections) => {
  let allStaticSectionModules = ImmutableMap();
  allStaticSections.forEach(currentStaticSection => {
    const moduleChildren = currentStaticSection.getModuleChildren();
    moduleChildren.forEach(module => {
      const name = module.getName();
      let moduleFromMap = layoutSectionWidgetsModuleMap.get(name);
      // This is to prevent breakage in that I currently don't correctly update the
      // `layoutSectionWidgetsModuleMap`
      // Once that is addressed, the if can be removed
      if (moduleFromMap) {
        // `isStaticSectionModule` is used with `getCurrentModuleToolbarInfo`
        // pulled out in `renderResponsiveToolbar`
        //  and then in used `buildActionsForModuleOutsideContainer`
        //  to add the 🚫 icon (with tooltip) to StaticSectionModules
        // @ts-expect-error not on the type
        moduleFromMap = moduleFromMap.set('isStaticSectionModule', true);
        allStaticSectionModules = allStaticSectionModules.set(name, moduleFromMap);
      }
    });
  });
  return allStaticSectionModules;
});
const getStaticSectionModulesImmer = createSelector([getLayoutSectionWidgetsModuleMap.ungated, getAllStaticSectionsFromTreesImmer], (layoutSectionWidgetsModuleMap, allStaticSections) => {
  const allStaticSectionModules = {};
  allStaticSections.forEach(currentStaticSection => {
    const moduleChildren = currentStaticSection.getModuleChildren();
    moduleChildren.forEach(module => {
      const name = module.getName();
      let moduleFromMap = layoutSectionWidgetsModuleMap[name];
      // This is to prevent breakage in that I currently don't correctly update the
      // `layoutSectionWidgetsModuleMap`
      // Once that is addressed, the if can be removed
      if (moduleFromMap) {
        // `isStaticSectionModule` is used with `getCurrentModuleToolbarInfo`
        // pulled out in `renderResponsiveToolbar`
        //  and then in used `buildActionsForModuleOutsideContainer`
        //  to add the 🚫 icon (with tooltip) to StaticSectionModules
        // TODO: I think there's a better way to do this. We shouldn't be mutating the module in the
        // selector
        moduleFromMap = Object.assign({}, moduleFromMap, {
          // @ts-expect-error Type doesn't know about this yet
          isStaticSectionModule: true
        });
        allStaticSectionModules[name] = moduleFromMap;
      }
    });
  });
  return allStaticSectionModules;
});

// Gather all static modules, those "editable" and not (like v1 globals not in any container)
const getAllStaticModules = createSelector(getAllEditableStaticModules.gated, getUneditableModules, getAllStaticGlobalV1Modules, getFakeModules, getStaticSectionModules, getIsUngatedForFixedLayoutSections, (allEditableStaticModules, uneditableModules, allStaticGlobalV1Modules, fakeModules, staticSectionModules, isUngatedForFixedLayoutSections) => {
  let modules = ImmutableMap(allEditableStaticModules)
  // @ts-expect-error types don't overlap but that's ok
  .merge(uneditableModules)
  // @ts-expect-error types don't overlap but that's ok
  .merge(allStaticGlobalV1Modules)
  // @ts-expect-error types don't overlap but that's ok
  .merge(fakeModules);

  // TODO: Clean this up when removing `CMS:FixedLayoutSections` gate.
  if (isUngatedForFixedLayoutSections) {
    // @ts-expect-error types don't overlap but that's ok
    modules = modules.merge(staticSectionModules);
  }
  return modules;
});
const getAllStaticModulesImmer = createSelector(getAllEditableStaticModules.ungated, getUneditableModules.ungated, getAllStaticGlobalV1Modules.ungated, getFakeModules.ungated, getStaticSectionModulesImmer, getIsUngatedForFixedLayoutSections, (allEditableStaticModules, uneditableModules, allStaticGlobalV1Modules, fakeModules, staticSectionModules, isUngatedForFixedLayoutSections) => {
  const modules = Object.assign({}, allEditableStaticModules, uneditableModules, allStaticGlobalV1Modules, fakeModules);

  // TODO: Clean this up when removing `CMS:FixedLayoutSections` gate.
  if (isUngatedForFixedLayoutSections) {
    Object.assign(modules, staticSectionModules);
  }
  return modules;
});
const MODULES_TO_NOT_SHOW_OVERLAYS_FOR = new Set();
MODULES_TO_NOT_SHOW_OVERLAYS_FOR.add('@hubspot/blog_content');
export const getAllStaticModulesToShowOverlaysOn = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getAllStaticModules, allStaticModules => allStaticModules.filter(module => {
  const isInPathFilterList = MODULES_TO_NOT_SHOW_OVERLAYS_FOR.has(module.get('path'));
  const isInsideGlobalPartialOrGroup = !!module.get('global_partial') || !!module.get('global_group_path');
  return !isInPathFilterList && !isInsideGlobalPartialOrGroup;
})), createSelector(getAllStaticModulesImmer, allStaticModules => {
  const moduleEntries = Object.entries(allStaticModules).filter(([__name, module]) => {
    const isInPathFilterList = MODULES_TO_NOT_SHOW_OVERLAYS_FOR.has(
    // @ts-expect-error TODO: Finalize minimal module type
    module.path);
    const isInsideGlobalPartialOrGroup =
    // @ts-expect-error TODO: Finalize minimal module type
    !!module.global_partial || !!module.global_group_path;
    return !isInPathFilterList && !isInsideGlobalPartialOrGroup;
  });
  return Object.fromEntries(moduleEntries);
}));
const filterModulesWithSmartContent = modules => modules.filter(module => {
  const smartType = module.get('smart_type');
  const smartObjects = module.get('smart_objects');
  return Boolean(smartType && smartObjects && !smartObjects.isEmpty());
}).toObject();
const filterModulesWithSmartContentImmer = modules => Object.fromEntries(Object.entries(modules).filter(([__key, module]) => {
  const smartType = module.smart_type;
  const smartObjects = module.smart_objects;
  return Boolean(smartType && smartObjects && !isEmpty(smartObjects));
}));
export const getModulesWithSmartContent = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModules.gated], filterModulesWithSmartContent),
// @ts-expect-error TODO: Finalize minimal module type
createSelector([getModules.ungated], filterModulesWithSmartContentImmer));
export const getUneditableModulesWithSmartContent = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getUneditableModules], modules => filterModulesWithSmartContent(ImmutableMap(modules))), createSelector(
// @ts-expect-error Finalize single module type
[getUneditableModules.ungated], filterModulesWithSmartContentImmer));
export const getAnyModulesHaveSmartContent = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModulesWithSmartContent.gated, getUneditableModulesWithSmartContent.gated], (modulesWithSmartContent, uneditableModulesWithSmartContent) => Object.keys(modulesWithSmartContent).length + Object.keys(uneditableModulesWithSmartContent).length > 0), createSelector([getModulesWithSmartContent.ungated, getUneditableModulesWithSmartContent.ungated], (modulesWithSmartContent, uneditableModulesWithSmartContent) => Object.keys(modulesWithSmartContent).length + Object.keys(uneditableModulesWithSmartContent).length > 0));

// returns the index of the smart object if the id matches the smart view criterion id
export const getModuleMatchingSmartObjectIndex = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const module = getModuleById.gated(state, id);
  const smartViewCriterionId = getSmartViewCriterionId(state);
  let matchingSmartObjectIndex = -1;
  if (module && module.has('smart_objects') && module.get('smart_objects') !== null && smartViewCriterionId) {
    // @ts-expect-error TS thinks that module could be undefined?
    matchingSmartObjectIndex = module.get('smart_objects').findIndex(smartObject => smartObject.get('criterion_id') === smartViewCriterionId);
  }
  return matchingSmartObjectIndex;
}), basicSelectorWithStats((state, id) => {
  const module = getModuleById.ungated(state, id);
  const smartViewCriterionId = getSmartViewCriterionId(state);
  let matchingSmartObjectIndex = -1;
  if (module &&
  // @ts-expect-error Finialize central module type
  module.smart_objects &&
  // @ts-expect-error Finialize central module type
  module.smart_objects !== null && smartViewCriterionId) {
    // @ts-expect-error Finialize central module type
    matchingSmartObjectIndex = module.smart_objects.findIndex(smartObject => smartObject.criterion_id === smartViewCriterionId);
  }
  return matchingSmartObjectIndex;
}));
export const getModuleMatchingSmartCriteriaIds = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, id) => {
  const module = getModuleById.gated(state, id);
  let criteriaIds = [];
  if (module && module.has('smart_objects') && module.get('smart_objects') !== null) {
    // @ts-expect-error TS thinks the module could be undefined?
    criteriaIds = module.get('smart_objects').map(smartObject => smartObject.get('criterion_id')).toJS();
  }
  return criteriaIds;
}), basicSelectorWithStats((state, id) => {
  const module = getModuleById.ungated(state, id);
  let criteriaIds = [];
  // @ts-expect-error Finialize central module type
  if (module && module.smart_objects && module.smart_objects !== null) {
    // @ts-expect-error Finialize central module type
    criteriaIds = module.smart_objects.map(smartObject => smartObject.criterion_id);
  }
  return criteriaIds;
}));
export const getSchemaLayoutSectionsAsJson = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModuleLists.gated, moduleLists => moduleLists.get('schemaLayoutSectionTrees').map(tree => {
  return exportForLayoutDataApi(tree);
}).toJS()), createSelector(getModuleLists.ungated, moduleLists => Object.fromEntries(Object.entries(moduleLists.schemaLayoutSectionTrees).map(([name, tree]) => {
  return [name, exportForLayoutDataApi(tree)];
}))));
export const getLayoutSectionsAsJson = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getLayoutSectionTrees, layoutSectionTrees => layoutSectionTrees.map(tree => {
  return exportForLayoutDataApi(tree);
}).toJS()), createSelector(getLayoutSectionTreesImmer, layoutSectionTrees => Object.fromEntries(Object.entries(layoutSectionTrees).map(([name, tree]) => {
  return [name, exportForLayoutDataApi(tree)];
}))));
export const getAllLayoutSectionIds = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getSchemaLayoutSectionTrees.gated], schemaLayoutSectionTrees => ImmutableMap.isMap(schemaLayoutSectionTrees) ? schemaLayoutSectionTrees.keySeq().toArray() : []), createSelector([getSchemaLayoutSectionTrees.ungated], schemaLayoutSectionTrees => Object.keys(schemaLayoutSectionTrees)));
export const getAllLayoutSectionTreesAsMap = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModuleLists.gated, getAllLayoutSectionIds.gated, (moduleLists, layoutSectionIds) => {
  return layoutSectionIds.reduce((result, layoutSectionId) => {
    const contentTree = moduleLists.getIn(['layoutSectionTrees', layoutSectionId]);
    const schemaTree = moduleLists.getIn(['schemaLayoutSectionTrees', layoutSectionId]);
    result[layoutSectionId] = contentTree || schemaTree;
    return result;
  }, {});
}), createSelector(getModuleLists.ungated, getAllLayoutSectionIds.ungated, (moduleLists, layoutSectionIds) => {
  return layoutSectionIds.reduce((result, layoutSectionId) => {
    const contentTree = moduleLists.layoutSectionTrees[layoutSectionId];
    const schemaTree = moduleLists.schemaLayoutSectionTrees[layoutSectionId];
    result[layoutSectionId] = contentTree || schemaTree;
    return result;
  }, {});
}));
export const makeGetLayoutSectionNodeById = id => createSelector([getAllLayoutSectionTreesAsMap], layoutTrees => {
  let node = null;
  forEachTree(layoutTrees, tree => {
    if (node) return;
    const root = tree.getRootCell();
    const rows = root.getRows();
    node = rows.find(row => row.getName() === id);
    if (!node) {
      const columns = root.getColumns();
      node = columns.find(column => column.getName() === id);
    }
  });
  return node;
});
export const getLayoutSectionRootIdById = basicSelectorWithStats((state, id) => {
  const layoutSection = makeGetLayoutSectionNodeById(id)(state);
  return layoutSection ? layoutSection.getRootName() : null;
});
export const getFlexAreaTreeAsMap = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModuleLists.gated, moduleLists => moduleLists.get('flexAreaTree')), createSelector(getModuleLists.ungated, moduleLists => moduleLists.flexAreaTree));
export const getHasAnyLayoutSections = createSelector([getAllLayoutSectionIds], allLayoutSectionIds => allLayoutSectionIds.length > 0);
export const getHasRichTextModuleThatSupportsModuleInsertion = createSelector([getFakePostBodyModule, getIsDataAuthoringModeActive, getIsUngatedForScpModuleInsertion], (fakePostBodyModule, isDataAuthoringModeActive, isUngatedForScpModuleInsertion) => Boolean(fakePostBodyModule || isDataAuthoringModeActive && isUngatedForScpModuleInsertion && EditorConfigSingleton.getFeatureFlagOrDefault('moduleInsertion')));
export const getHasAnyContainerOrDndAreaToAddModules = createSelector([getHasContainers, getHasAnyLayoutSections, getHasRichTextModuleThatSupportsModuleInsertion], (hasAnyContainers, hasAnyLayoutSections, hasRichTextModuleThatSupportsModuleInsertion) => {
  return hasAnyContainers || hasAnyLayoutSections || hasRichTextModuleThatSupportsModuleInsertion;
});
export const getContentLayoutSectionTreeById = basicSelectorWithStats((state, layoutSectionId) => {
  const contentLayoutSectionTrees = getContentLayoutSectionTrees(state);
  return isImmutable(contentLayoutSectionTrees) ? contentLayoutSectionTrees.get(layoutSectionId) : contentLayoutSectionTrees[layoutSectionId];
});
export const getSchemaLayoutSectionTreeById = basicSelectorWithStats((state, layoutSectionId) => {
  const schemaLayoutSectionTrees = getSchemaLayoutSectionTrees(state);
  return isImmutable(schemaLayoutSectionTrees) ? schemaLayoutSectionTrees.get(layoutSectionId) : schemaLayoutSectionTrees[layoutSectionId];
});
export const getLayoutSectionTreeById = basicSelectorWithStats((state, {
  layoutSectionId
}) => {
  const contentTree = getContentLayoutSectionTreeById(state, layoutSectionId);
  const schemaTree = getSchemaLayoutSectionTreeById(state, layoutSectionId);
  return contentTree || schemaTree;
});
export const getLayoutSectionIsEmpty = basicSelectorWithStats((state, layoutSectionId) => {
  return getLayoutSectionTreeById(state, {
    layoutSectionId
  }).isEmpty();
});
export const getCellOrRowByIdHelper = (tree, id) => {
  if (tree && tree.hasRow(id)) {
    return tree.findRow(id);
  } else if (tree && tree.hasCell(id)) {
    return tree.findCell(id);
  }
  return null;
};
export const getCellOrRowById = basicSelectorWithStats((state, {
  id,
  layoutSectionId
}) => {
  const tree = getLayoutSectionTreeById(state, {
    layoutSectionId
  });
  return getCellOrRowByIdHelper(tree, id);
});
export const makeGetCellOrRowById = () => createSelector(getCellOrRowById, node => node);
export const makeGetCellOrRowParentId = () => createSelector(getCellOrRowById, cell => {
  if (cell) {
    return cell.getParentName();
  }
  return null;
});
export const makeGetIsOnlyChildInParent = () => createSelector(getCellOrRowById, cell => {
  const parent = cell.getParent();
  if (parent.isRow()) {
    return parent.getNumberColumns() === 1;
  } else if (parent.isCell()) {
    return parent.getNumberRows() === 1;
  }
  return null;
});
export const makeGetCanCellBeCloned = () => createSelector(getCellOrRowById, cell => {
  if (!cell || !cell.isCell()) {
    return undefined;
  }

  // If cloning a single module in a column, clone the parent column instead
  const cellToClone = cell.isOnlyModuleInWrapperColumn() ? cell.getParent().getParent() : cell;
  return cellToClone.getParent().hasRoomForMoreColumns();
});
const getSchemaForBuiltinModuleType = basicSelectorWithStats((state, moduleType) => {
  if (moduleType !== 'module') {
    return getBuiltinModuleSchemaByType(state)[moduleType];
  }
  return null;
});
export const getModuleIcon = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats((state, moduleId) => {
  const module = getModuleById.gated(state, moduleId);
  if (module) {
    const schemaFromBuiltInMapping = getSchemaForBuiltinModuleType(state, module.get('type'));
    const schema = getSchemaForModule(state, module);
    return getIconForModule(module, schema, schemaFromBuiltInMapping);
  }
  return null;
}), basicSelectorWithStats((state, moduleId) => {
  const module = getModuleById.ungated(state, moduleId);
  if (module) {
    const schemaFromBuiltInMapping = getSchemaForBuiltinModuleType(state,
    // @ts-expect-error TOOD: Finialize minimal module type
    module.type);
    const schema = getSchemaForModule(state, module);
    return getIconForModule(module, schema, schemaFromBuiltInMapping);
  }
  return null;
}));
export const getModuleGroups = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getHiddenModules.gated, getSortedStaticModulesAndContainers.gated, getAllFlexColumnModulesAndContainer.gated, getAllLayoutSectionTreesAsMap.gated, getPostBodyModulesAsLayoutTree.gated], (hiddenModules, sortedStaticModulesAndContainer, allFlexColumnModulesAndContainer, allLayoutSectionTreesAsMap, postBodyModulesAsTree) => {
  const moduleGroups = {};
  if (hiddenModules.size > 0) {
    moduleGroups.hiddenModules = {
      index: -2,
      type: 'module',
      label: I18n.text('widgetList.hiddenModulesHeader'),
      modules: hiddenModules
    };
  }
  if (postBodyModulesAsTree) {
    const rootCell = postBodyModulesAsTree.getRootCell();
    moduleGroups[BLOG_POST_BODY_MODULES] = {
      index: -1,
      type: 'layout_section',
      label: rootCell.getValue().label,
      name: BLOG_POST_BODY_MODULES,
      modules: rootCell
    };
  }
  sortedStaticModulesAndContainer.entrySeq().forEach(([id, body], index, sortedStaticModulesAndContainerArray) => {
    const type = body.get('type');
    const label = body.get('label');
    const name = body.get('name');
    if (type === 'container') {
      moduleGroups[id] = {
        index,
        type,
        label: label || name,
        modules: getModulesInContainerHelper(allFlexColumnModulesAndContainer, id)
      };
    } else if (type === 'layout_section') {
      moduleGroups[id] = {
        index,
        name,
        type,
        label,
        modules: allLayoutSectionTreesAsMap[name].getRootCell()
      };
    } else if (type === 'module') {
      if (index > 0) {
        const prevEntry = sortedStaticModulesAndContainerArray.get(index - 1);
        const [__prevId, prevBody] = prevEntry;
        // if the last item is a module, we used it to get all its neighbor modules
        if (prevBody.get('type') === 'module') return null;
      }
      // only look at entries from this point forward
      const sortedStaticModulesAndContainersEntriesSubset = sortedStaticModulesAndContainerArray.skip(index);
      let staticModules = List([]);
      sortedStaticModulesAndContainersEntriesSubset.forEach(([__itemId, itemBody]) => {
        // iteration stops when false is returned https://immutable-js.com/docs/v4.0.0/List/#forEach()
        if (itemBody.get('type') !== 'module') return false;
        staticModules = staticModules.push(itemBody);
        return staticModules;
      });
      moduleGroups[id] = {
        index,
        type,
        label: I18n.text('widgetList.staticModulesHeader'),
        modules: staticModules
      };
    } else {
      moduleGroups[id] = {
        index,
        type,
        label,
        modules: Immutable.List([body])
      };
    }
    return null;
  });
  // Using Map to preserve insertion order
  return new Map(Object.entries(moduleGroups).sort(([__keyA, valueA], [__keyB, valueB]) => valueA.index - valueB.index));
}), createSelector([getHiddenModules.ungated, getSortedStaticModulesAndContainers.ungated, getAllFlexColumnModulesAndContainer.ungated, getAllLayoutSectionTreesAsMap.ungated, getPostBodyModulesAsLayoutTree.ungated], (hiddenModules, sortedStaticModulesAndContainer, allFlexColumnModulesAndContainer, allLayoutSectionTreesAsMap, postBodyModulesAsTree) => {
  const moduleGroups = {};
  if (Object.keys(hiddenModules).length > 0) {
    moduleGroups.hiddenModules = {
      index: -2,
      type: 'module',
      label: I18n.text('widgetList.hiddenModulesHeader'),
      // @ts-expect-error TODO: Finalize minimal module type
      modules: hiddenModules
    };
  }
  if (postBodyModulesAsTree) {
    const rootCell = postBodyModulesAsTree.getRootCell();
    moduleGroups[BLOG_POST_BODY_MODULES] = {
      index: -1,
      type: 'layout_section',
      label: rootCell.getValue().label,
      name: BLOG_POST_BODY_MODULES,
      modules: rootCell
    };
  }
  Object.entries(sortedStaticModulesAndContainer).forEach(([id, body], index, sortedStaticModulesAndContainerArray) => {
    const type = body.type;
    const label = body.label;
    const name = body.name;
    if (type === 'container') {
      moduleGroups[id] = {
        index,
        type,
        label: label || name,
        modules: getModulesInContainerHelperImmer(allFlexColumnModulesAndContainer, id)
      };
    } else if (type === 'layout_section') {
      moduleGroups[id] = {
        index,
        name,
        type,
        label,
        modules: allLayoutSectionTreesAsMap[name].getRootCell()
      };
    } else if (type === 'module') {
      if (index > 0) {
        const prevEntry = sortedStaticModulesAndContainerArray.at(index - 1);
        const [__prevId, prevBody] = prevEntry;
        // if the last item is a module, we used it to get all its neighbor modules
        if (prevBody.type === 'module') return;
      }
      // only look at entries from this point forward
      const sortedStaticModulesAndContainersEntriesSubset = sortedStaticModulesAndContainerArray.slice(index);
      const staticModules = [];
      for (const entry of sortedStaticModulesAndContainersEntriesSubset) {
        const [__itemId, itemBody] = entry;
        if (itemBody.type !== 'module') break;
        // @ts-expect-error we know that it is a static module at this point
        staticModules.push(itemBody);
      }
      moduleGroups[id] = {
        index,
        type,
        label: I18n.text('widgetList.staticModulesHeader'),
        modules: staticModules
      };
    } else {
      moduleGroups[id] = {
        index,
        // @ts-expect-error TODO: Fix this type
        type,
        label,
        // @ts-expect-error TODO: Finalize minimal module type
        modules: [body]
      };
    }
  });
  // Using Map to preserve insertion order
  return new Map(Object.entries(moduleGroups).sort(([__keyA, valueA], [__keyB, valueB]) => valueA.index - valueB.index));
}));
const getLocalClonedModulesPendingDomOperations = createSelector(getModuleLists.gated, moduleLists => moduleLists.get('clonedModulesPendingDomOperations'));
const getLocalClonedModulesPendingDomOperationsImmer = createSelector(getModuleLists.ungated, moduleLists => moduleLists.clonedModulesPendingDomOperations);
export const getClonedModulesPendingDomOperations = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getLocalClonedModulesPendingDomOperations, clonedModulesPendingDomOperations => clonedModulesPendingDomOperations.toJS()), createSelector(getLocalClonedModulesPendingDomOperationsImmer, clonedModulesPendingDomOperations => Array.from(clonedModulesPendingDomOperations.values())));
const getLocalMapOfClonedToOldStaticSectionName = createSelector(getModuleLists.gated, moduleLists => moduleLists.get('mapOfClonedToOldStaticSectionName'));
export const getMapOfClonedToOldStaticSectionName = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getLocalMapOfClonedToOldStaticSectionName, mapOfClonedToOldStaticSectionName => mapOfClonedToOldStaticSectionName.toJS()), createSelector(getModuleLists.ungated, moduleLists => moduleLists.mapOfClonedToOldStaticSectionName));

// TODO making content type checks in CEUI is a pattern we try to avoid!
// Doing it here because components in IPEUI use this selector, and we do
// not want to roll out undo/redo to email yet.
// TODO: Fix this for scalable editor
export const getHasUndoRedoAccess = createSelector([getIsPage, getIsBlogPost], (isPage, isBlog) => isPage || isBlog || EditorConfigSingleton.getIsOnScalableEditor() && EditorConfigSingleton.getFeatureFlagOrDefault('undoRedo'));
export const getPostBodyCustomModuleParentIds = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelectorWithStats(state => {
  const module = getModuleById.gated(state, 'post_body');
  // @ts-expect-error we're using module as a discriminany union here
  if (module && module.get('isInCustomModule')) {
    var _module$get;
    // @ts-expect-error we're using module as a discriminany union here
    return (_module$get = module.get('customModuleParentIds')) === null || _module$get === void 0 ? void 0 : _module$get.toArray();
  }
  return null;
}), basicSelectorWithStats(state => {
  const module = getModuleById.ungated(state, 'post_body');
  // @ts-expect-error we're using module as a discriminany union here
  if (module && module.isInCustomModule) {
    // @ts-expect-error we're using module as a discriminany union here
    return module.customModuleParentIds;
  }
  return null;
}));
export const getFlexAreaIndex = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getFlexAreasMetadata.gated, metadata => metadata.get(DND_AREA_ID)), createSelector(getFlexAreasMetadata.ungated, metadata => metadata[DND_AREA_ID]));
export const getEmbedInfoAsJsObject = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getEmbedInfo.gated], embedInfo => embedInfo.toJS()), createSelector([getEmbedInfo.ungated], embedInfo => embedInfo));
export const EMBED_LIMIT = 10;
export const getHasReachedEmbedLimit = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getAllLayoutSectionTreesAsMap.gated], layoutTrees => {
  let totalEmbeds = 0;
  forEachTree(layoutTrees, tree => {
    const root = tree.getRootCell();
    root.getRows().forEach(row => {
      if (row.isEmbed()) {
        totalEmbeds++;
      }
    });
  });
  return totalEmbeds >= EMBED_LIMIT;
}), createSelector([getAllLayoutSectionTreesAsMap.ungated], layoutTrees => {
  let totalEmbeds = 0;
  Object.values(layoutTrees).forEach(tree => {
    const root = tree.getRootCell();
    root.getRows().forEach(row => {
      if (row.isEmbed()) {
        totalEmbeds++;
      }
    });
  });
  return totalEmbeds >= EMBED_LIMIT;
}));
export const getAllNormalizedFormModulesInsideLayout = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModulesInsideLayoutSections.gated], modules => {
  return ImmutableMap(modules).filter(module => module.getIn(['body', 'path']) === '@hubspot/form' &&
  // Only consider providing XRay recommendations for valid forms.
  // Invalid forms, forms without a form_id, can be generated from a
  // page template. Follow up emails and automations can only be linked
  // to forms that are saved assets, which is indicated by an existing
  // form_id value
  module.getIn(['body', 'form', 'form_id'])).toList().toJS().map(module => ({
    formModuleId: module.id,
    // @ts-expect-error we know the type of module at this point
    followUpEmailId: module.body.form.followup_email_id
  }));
}), createSelector([getModulesInsideLayoutSections.ungated], modules => {
  return Object.values(modules).reduce((normalizedFormModules, module) => {
    var _module$body, _module$body2;
    if (((_module$body = module.body) === null || _module$body === void 0 ? void 0 : _module$body.path) === '@hubspot/form' && // Only consider providing XRay recommendations for valid forms.
    // Invalid forms, forms without a form_id, can be generated from a
    // page template. Follow up emails and automations can only be linked
    // to forms that are saved assets, which is indicated by an existing
    // form_id value
    // @ts-expect-error TS does not know about body values
    (_module$body2 = module.body) !== null && _module$body2 !== void 0 && (_module$body2 = _module$body2.form) !== null && _module$body2 !== void 0 && _module$body2.form_id) {
      const normalizedFormModule = {
        formModuleId: module.id,
        // @ts-expect-error we know the type of module at this point
        followUpEmailId: module.body.form.followup_email_id
      };
      normalizedFormModules.push(normalizedFormModule);
    }
    return normalizedFormModules;
  }, []);
}));
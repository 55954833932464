/**
 * These constants are for the following INTERNAL use cases:
 *   - tinymce-config/static/js/utils/plugins.ts for enabled feature filtering
 *   - test specs
 * To use in the config, import each re-exported constant from the index file of the associated plugin.
 */
export const __HS_CORE = 'hscore';
export const __HS_I18N = 'hsi18n';
export const __HS_PROTECT = 'hsprotect';
export const __HS_HR = 'hshr';
export const __HS_NONBREAKING = 'hsnonbreaking';
export const __HS_IMAGE = 'hsimage_v3';
export const __HS_IMAGE_INPAGE = 'hsimage_v3_inpage';
export const __HS_LINK = 'hslink_v2_inapp';
export const __HS_LINK_INPAGE = 'hslink_v2_inpage';
export const __HS_PERSONALIZE = 'hspersonalize_v2';
export const __HS_DATA_TOKEN = 'hsdatatokens';
export const __HS_FORMATTING = 'hsformatting';
export const __HS_CTA = 'hscta_v2';
export const __HS_CTA_INPAGE = 'hscta_v2_inpage';
export const __HS_CODE = 'hscode_v2';
export const __HS_CODE_INPAGE = 'hscode_v2_inpage';
export const __HS_MORE = 'hsmore_v2';
export const __HS_MEDIA = 'hsmedia';
export const __HS_MEDIA_INPAGE = 'hsmedia_inpage';
export const __HS_VIDEO = 'hsvideo_v2';
export const __HS_VIDEO_INPAGE = 'hsvideo_v2_inpage';
export const __HS_COLOR = 'hscolor';
export const __HS_RSS_TOKEN = 'hsrsstoken_v2';
export const __HS_PASTE = 'hspaste';
export const __HS_EMAIL_EDITOR = 'hsemaileditor';
export const __HS_EMOJI = 'hsemoji';
export const __HS_EMOJI_INPAGE = 'hsemoji_inpage';
export const __HS_SNIPPET = 'hssnippet';
export const __HS_TABLE = 'hstable2';
export const __HS_SETTING_PASSTHROUGH = 'hssettingpassthrough';
export const __HS_NEWLINE = 'hsnewline';
export const __HS_LISTS = 'hslists';
export const __HS_VISUAL_BLOCKS = 'hsvisualblocks';
export const __HS_ANCHOR = 'hsanchor';
export const __HS_TRANSFORM_ALERT = 'hstransformalert';
export const __HS_TRANSFORM_ALERT_INPAGE = 'hstransformalert_inpage';
export const __HS_CALLOUT = 'hscallout';
export const __HS_CHARMAP = 'hscharmap';
export const __HS_FIND_REPLACE = 'hsfindreplace';
export const __HS_MODULE_INSERTION = 'hsmoduleinsertion';
export const __HS_ICON = 'hsicon';
export const __HS_ESCAPE_NEWLINES = 'hsescapenewlines';
export const __HS_CONTENT_GENERATION = 'hscontentgeneration';
export const __HS_AUTO_COMPLETE = 'hsautocomplete';
export const __HS_COPILOT_COLLABORATION = 'hscopilotcollaboration';
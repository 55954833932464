import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { TABS, getInitialCollaborationSidebarState } from 'collaboration-sidebar';
import { getIsUngatedForCommentPopOver } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getCollaborationSidebarCommentPopoverState } from './modalSelectors';
const EMPTY_STATE = getInitialCollaborationSidebarState();
export const getCollaborationSidebarState = basicSelector(state => state.collaborationSidebar || EMPTY_STATE);
export const getIsCommentModeActive = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.isCommentModeActive);
export const getIsOnCommentingTab = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.isOpen && collaborationSidebarState.tab === TABS.COMMENTS);
export const getHasUnrecoverableError = createSelector([getCollaborationSidebarState], collaborationSidebarState => collaborationSidebarState.hasUnrecoverableError);
export const makeGetHasComments = name => {
  return createSelector([getCollaborationSidebarState, getIsUngatedForCommentPopOver], (collaborationSidebarState, isUngatedForCommentPopOver) => {
    const modulesWithReplies = collaborationSidebarState.modulesWithReplies;
    return isUngatedForCommentPopOver && modulesWithReplies && modulesWithReplies.hasOwnProperty(name);
  });
};
export const makeGetHasUnreadComments = name => {
  return createSelector([getCollaborationSidebarState, getIsUngatedForCommentPopOver], (collaborationSidebarState, isUngatedForCommentPopOver) => {
    const {
      modulesWithReplies,
      unreadRepliesMap
    } = collaborationSidebarState;
    if (!isUngatedForCommentPopOver || !modulesWithReplies[name]) {
      return false;
    }
    const moduleWithReplies = modulesWithReplies[name];
    return (
      // True if the root comment is unread
      !moduleWithReplies.read ||
      // or if there are unread comments in its corresponding thread
      Boolean(unreadRepliesMap[moduleWithReplies.id]) && unreadRepliesMap[moduleWithReplies.id].length > 0
    );
  });
};
export const makeGetShouldShowCommentPopOverForAsset = id => {
  return createSelector([getCollaborationSidebarCommentPopoverState], collaborationSidebarCommentPopoverState => {
    const {
      id: currentId,
      isOpen
    } = collaborationSidebarCommentPopoverState;
    return isOpen && currentId === id;
  });
};
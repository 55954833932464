import { List, Map as ImmutableMap, fromJS } from 'immutable';
// @ts-expect-error not typed
import { exportCellAsModule } from 'layout-data-lib/LayoutDataTree/serialize';
import { createSelector } from 'reselect';
import { createUndoableSelector, createUndoableSelectorPOJO } from 'ContentEditorUI/redux/selectors/undoRedoSelectors';
// @ts-expect-error not typed
import { getAllFieldNamesOnModuleSpec } from 'ContentEditorUI/data/moduleUtils';
import { getModuleSchemas, getAllModuleSchemasByModuleId } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { normalizedId, normalizedKey, getWidgetBodyFromSchema, defaultContentWidgetAttributes, attributesPickedFromContentWidget, getContentWidgetForWidgetOutsideContainer, ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET, normalizedIdImmer, attributesPickedFromContentWidgetImmer, defaultContentWidgetAttributesImmer, getWidgetBodyFromSchemaImmer, normalizedKeyImmer, getContentWidgetForWidgetOutsideContainerImmer } from 'ContentEditorUI/data/moduleTransformHelpers';
import { getAuth, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, getIsUngatedForCustomBreakpoints, getIsUngatedForImmerModuleReducer } from './authSelectors';
import { basicSelector, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';

// @ts-expect-error Not typed yet

import produce from 'immer';
import mergeIn from '../../lib/mergeIn';
import get from 'hs-lodash/get';
import has from 'hs-lodash/has';
import isEmpty from 'hs-lodash/isEmpty';
import { getImmutableOrPlain, isImmutable } from '../../utils/dataHelpers';
// TODO Use this correctly in inpageSelectors

export const getUndoableModules = makeGatedSelector(getIsUngatedForImmerModuleReducer, basicSelector(state => state.modules), basicSelector(state => state.modules));
const _getModuleListsImmer = createSelector([getUndoableModules.ungated], undoableModules => undoableModules.present);
const _getModuleLists = createSelector([getUndoableModules.gated], undoableModules => undoableModules.get('present'));

// Casting this selector to avoid a whole bunch of null checks for values we know exist
export const getModuleLists = makeGatedSelector(getIsUngatedForImmerModuleReducer, createUndoableSelector(getUndoableModules.gated, 'present'), createUndoableSelectorPOJO(getUndoableModules.ungated, 'present'));
export const buildFakeModuleSlice = (moduleLists, auth) => ({
  // @ts-expect-error not changing immutable logic
  modules: new ImmutableMap({
    present: moduleLists
  }),
  auth
});
const createModuleListsSelector = key => makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getModuleLists.gated, moduleLists => moduleLists ? moduleLists.get(key) : ImmutableMap()), createSelector(getModuleLists.ungated, moduleLists => moduleLists[key]));
const getSchemaWidgets = createModuleListsSelector('schemaWidgets');
export const getContentWidgets = createModuleListsSelector('widgets');
export const getEmbedInfo = createModuleListsSelector('embedInfo');
export const getWidgetsInRichText = createModuleListsSelector('widgetsInRichText');
const getSchemaWidgetContainers = createModuleListsSelector('schemaWidgetContainers');
const getContentWidgetContainersImmer = createSelector(getModuleLists.ungated, moduleLists => {
  var _moduleLists$widgetCo;
  let widgetContainers = (_moduleLists$widgetCo = moduleLists === null || moduleLists === void 0 ? void 0 : moduleLists.widgetContainers) !== null && _moduleLists$widgetCo !== void 0 ? _moduleLists$widgetCo : {};
  // Inspect the widgetContainers to make sure each has atleast an empty
  // widgets list. Some legacy pages don't have this
  Object.values(widgetContainers).forEach((container, key) => {
    if (!Object.hasOwn(container, 'widgets')) {
      const emptyContainer = produce(container, draft => {
        draft.widgets = [];
      });
      widgetContainers = produce(widgetContainers, draft => {
        draft[key] = emptyContainer;
      });
    }
  });
  return widgetContainers;
});
const getContentWidgetContainers = createSelector(getModuleLists.gated, moduleLists => {
  let widgetContainers = moduleLists.get('widgetContainers');
  // Inspect the widgetContainers to make sure each has atleast an empty
  // widgets list. Some legacy pages don't have this
  widgetContainers.forEach((container, key) => {
    if (!container.has('widgets')) {
      // @ts-expect-error not migrating as this was here originally
      const emptyContainer = container.set('widgets', new List());
      widgetContainers = widgetContainers.set(key, emptyContainer);
    }
  });
  return widgetContainers;
});
export const getSchemaWidgetsInFlexColumnsByKey = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getSchemaWidgetContainers.gated, schemaWidgetContainers => schemaWidgetContainers.map(schemaContainer =>
// @ts-expect-error not migrating as this was here originally
new ImmutableMap(schemaContainer.get('widgets').map(module => [module.get('key'), module])))), createSelector(getSchemaWidgetContainers.ungated, schemaWidgetContainers => Object.fromEntries(Object.entries(schemaWidgetContainers).map(([containerId, schemaContainer]) => {
  const modulesByKeyMap = Object.fromEntries(schemaContainer.widgets.map(module => [module.key, module]));
  return [containerId, modulesByKeyMap];
}))));
export const getContentWidgetsInFlexColumnsById = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getContentWidgetContainers, contentWidgetContainers => contentWidgetContainers.map(contentContainer =>
// @ts-expect-error not migrating as this was here originally
new ImmutableMap(contentContainer.get('widgets').map(module => [module.get('id'), module])))), createSelector(getContentWidgetContainersImmer, contentWidgetContainers => Object.fromEntries(Object.entries(contentWidgetContainers).map(([containerId, contentContainer]) => {
  const moduleNameToModuleMap = Object.fromEntries(contentContainer.widgets.map(module => [module.id, module]));
  return [containerId, moduleNameToModuleMap];
}))));
export const getSchemaLayoutSectionTrees = createModuleListsSelector('schemaLayoutSectionTrees');
export const getContentLayoutSectionTrees = createModuleListsSelector('layoutSectionTrees');
export const getContentFlexAreaSections = createModuleListsSelector('flexAreaSections');
export const getContentFlexAreaStyles = createModuleListsSelector('flexAreaStyles');
export const getFlexAreasMetadata = createModuleListsSelector('flexAreasMetadata');
export const getUserModuleDefaults = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector([getModuleLists.gated], moduleLists => moduleLists.get('userModuleDefaults')), createSelector([getModuleLists.ungated], moduleLists => moduleLists.userModuleDefaults));
const getAllTreeNamesImmer = createSelector(getSchemaLayoutSectionTrees.ungated, schemaLayoutSectionTrees => Object.keys(schemaLayoutSectionTrees));
const getAllTreeNames = createSelector(getSchemaLayoutSectionTrees.gated, schemaLayoutSectionTrees => schemaLayoutSectionTrees.keySeq().toArray());
const getLayoutSectionWidgetsImmer = createSelector(getModuleLists.ungated, moduleLists => moduleLists.layoutSectionWidgets);
const getLayoutSectionWidgets = createSelector(getModuleLists.gated, moduleLists => moduleLists.get('layoutSectionWidgets'));

// Fancy multi-level memoization selectors for modules outside a D&D area

// A little weird, but a function to _fake_ the global state with only the parts needed
// for the fancy custom internal selectors used by `makeCachedStaticModulesSelector`
// (so other standard selectors like `getSchemaWidgets` can be re-used across both)
const buildFakeOutsideContainerState = (schemaWidgets, contentWidgets, auth) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaWidgets,
      widgets: contentWidgets
    })
  }),
  auth
});

// TODO: Replace this with a real type

const buildFakeOutsideContainerStateImmer = (schemaWidgets, contentWidgets, auth) => ({
  modules: {
    present: {
      schemaWidgets,
      widgets: contentWidgets
    }
  },
  auth
});
const makeGetSpecificSchemaWidgetOutsideContainerImmer = moduleName => createSelector(getSchemaWidgets.ungated, schemaWidgets => schemaWidgets[moduleName]);
const makeGetSpecificSchemaWidgetOutsideContainer = moduleName => createSelector(getSchemaWidgets.gated, schemaWidgets => schemaWidgets.get(moduleName));
const makeGetSpecificContentWidgetOutsideContainerImmer = (moduleName, getSpecificSchemaWidgetOutsideContainer) => createSelector(getSpecificSchemaWidgetOutsideContainer, getContentWidgets.ungated, (schemaWidget, contentWidgets) => getContentWidgetForWidgetOutsideContainerImmer(schemaWidget, contentWidgets));
const makeGetSpecificContentWidgetOutsideContainer = (moduleName, getSpecificSchemaWidgetOutsideContainer) => createSelector(getSpecificSchemaWidgetOutsideContainer, getContentWidgets.gated, (schemaWidget, contentWidgets) =>
// Ugly cast here, but the TypedMap types are getting a bit out of hand
getContentWidgetForWidgetOutsideContainer(schemaWidget, contentWidgets));
export const hydrateWidgetFromSchemaAndContentImmer = (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody) => {
  const id = normalizedIdImmer(schemaWidget, contentWidget);
  const key = normalizedKeyImmer(schemaWidget, contentWidget);
  return Object.assign({}, defaultContentWidgetAttributesImmer(schemaWidget, contentWidget), schemaWidget, {
    body: isEmpty(schemaWidget) ? contentWidget.body : getWidgetBodyFromSchemaImmer(schemaWidget)
  }, attributesPickedFromContentWidgetImmer(contentWidget, schemaWidget, {
    isUngatedToMergeTemplateSchemaBodyIntoContentBody
  }), {
    id,
    key
  });
};
export const hydrateWidgetFromSchemaAndContent = (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody) => {
  const id = normalizedId(schemaWidget, contentWidget);
  const key = normalizedKey(schemaWidget, contentWidget);
  return (
    // Can't use ts-expect-error here because of prettier but the issue is that TypedMap
    // doesn't work well with partials
    defaultContentWidgetAttributes(schemaWidget, contentWidget)
    // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
    .merge(schemaWidget).set('body',
    // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
    schemaWidget.isEmpty() ? contentWidget.get('body') :
    // @ts-expect-error TypedMap doesn't work well with partials
    getWidgetBodyFromSchema(schemaWidget)).merge(
    // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
    attributesPickedFromContentWidget(contentWidget, schemaWidget, {
      isUngatedToMergeTemplateSchemaBodyIntoContentBody
    })).set('id', id)
    // @ts-expect-error we are setting a key on a static module, even though the key only comes from the flex column modules
    .set('key', key)
  );
};
const makeGetSpecificModuleOutsideContainerImmer = moduleName => {
  const getSpecificSchemaWidgetOutsideContainer = makeGetSpecificSchemaWidgetOutsideContainerImmer(moduleName);
  return createSelector(getSpecificSchemaWidgetOutsideContainer,
  // @ts-expect-error Don't want to change logic right now, but technically this could result in a bug
  makeGetSpecificContentWidgetOutsideContainerImmer(moduleName, getSpecificSchemaWidgetOutsideContainer), getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, hydrateWidgetFromSchemaAndContentImmer);
};
const makeGetSpecificModuleOutsideContainer = moduleName => {
  const getSpecificSchemaWidgetOutsideContainer = makeGetSpecificSchemaWidgetOutsideContainer(moduleName);
  return createSelector(getSpecificSchemaWidgetOutsideContainer, makeGetSpecificContentWidgetOutsideContainer(moduleName, getSpecificSchemaWidgetOutsideContainer), getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, hydrateWidgetFromSchemaAndContent);
};

// TODO: Gate this one down stream in moduleSelectors
export const makeCachedStaticModulesSelectorImmer = () => {
  const cachedModuleSelectors = {};
  return createSelector(getSchemaWidgets.ungated, getContentWidgets.ungated, getAuth, (schemaWidgets, contentWidgets, auth) => {
    return Object.fromEntries(Object.entries(schemaWidgets).filter(([__name, schemaWidget]) => {
      // Exclude any "modules" that are inside a global group or partial and not directly uneditable
      const isInsideGlobalGroupOrPartial = Boolean(schemaWidget.global_group_path) || Boolean(schemaWidget.global_partial_path);
      return !isInsideGlobalGroupOrPartial;
    }).map(([name, schemaWidget]) => {
      // @ts-expect-error TODO: schemaWidget apprently doesn't have an id?
      const moduleName = schemaWidget.name || schemaWidget.id;
      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleOutsideContainerImmer(moduleName);
      }
      const fakedState = buildFakeOutsideContainerStateImmer(schemaWidgets, contentWidgets, auth);
      return [name, cachedModuleSelectors[moduleName](fakedState)];
    }));
  });
};
export const makeCachedStaticModulesSelector = () => {
  const cachedModuleSelectors = {};
  return createSelector(getSchemaWidgets.gated, getContentWidgets.gated, getAuth, (schemaWidgets, contentWidgets, auth) => {
    return schemaWidgets.filter(schemaWidget => {
      // Exclude any "modules" that are inside a global group or partial and not directly uneditable
      const isInsideGlobalGroupOrPartial = schemaWidget.has('global_group_path') || schemaWidget.has('global_partial_path');
      return !isInsideGlobalGroupOrPartial;
    }).map(schemaWidget => {
      const moduleName = schemaWidget.get('name') || schemaWidget.get('id');
      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleOutsideContainer(moduleName);
      }
      const fakedState = buildFakeOutsideContainerState(schemaWidgets, contentWidgets, auth);
      return cachedModuleSelectors[moduleName](fakedState);
    }).toObject();
  });
};

// Fancy multi-level memoization selectors for modules inside flex columns

const buildFakeModuleSliceStateForFlexColumns = (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaWidgetContainers,
      widgetContainers: contentWidgetContainers
    })
  }),
  auth,
  moduleSchemas
});
const buildFakeModuleSliceStateForFlexColumnsImmer = (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => ({
  modules: {
    present: {
      schemaWidgetContainers,
      widgetContainers: contentWidgetContainers
    }
  },
  auth,
  moduleSchemas
});
const makeGetSpecificSchemaWidgetInsideFlexColumnImmer = (moduleId, containerId) => createSelector(getSchemaWidgetsInFlexColumnsByKey.ungated, schemaWidgetsById => schemaWidgetsById[containerId][moduleId]);
const makeGetSpecificSchemaWidgetInsideFlexColumn = (moduleId, containerId) => createSelector(getSchemaWidgetsInFlexColumnsByKey.gated, schemaWidgetsById => schemaWidgetsById.get(containerId).get(moduleId));
const makeGetSpecificContentWidgetInsideFlexColumnImmer = (moduleId, containerId) => createSelector(getContentWidgetsInFlexColumnsById.ungated, contentWidgetsById => {
  const container = contentWidgetsById[containerId] || {};
  return container[moduleId];
});
const makeGetSpecificContentWidgetInsideFlexColumn = (moduleId, containerId) => createSelector(getContentWidgetsInFlexColumnsById.gated, contentWidgetsById => {
  return contentWidgetsById.get(containerId, ImmutableMap()).get(moduleId);
});
const makeCachedSpecificModuleInsideFlexColumnSelectorImmer = (moduleId, containerId) => {
  const getSpecificSchemaWidgetInsideFlexColumn = makeGetSpecificSchemaWidgetInsideFlexColumnImmer(moduleId, containerId);
  const getSpecificContentWidgetInsideFlexColumn = makeGetSpecificContentWidgetInsideFlexColumnImmer(moduleId, containerId);
  return createSelector(getSpecificSchemaWidgetInsideFlexColumn, getSpecificContentWidgetInsideFlexColumn, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, getAllModuleSchemasByModuleId, (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody, allModuleSchemasByModuleId) => {
    const id = normalizedIdImmer(schemaWidget, contentWidget);
    const key = normalizedKeyImmer(schemaWidget, contentWidget);
    const moduleSpecId = schemaWidget && schemaWidget.module_id || contentWidget && contentWidget.module_id;
    let moduleSpec;
    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];
    }

    // Hydrate widget from schema and contentWidget
    const hydratedModule = Object.assign({}, defaultContentWidgetAttributesImmer(schemaWidget, contentWidget), schemaWidget, {
      body: getWidgetBodyFromSchemaImmer(schemaWidget)
    }, attributesPickedFromContentWidgetImmer(contentWidget, schemaWidget, {
      isUngatedToMergeTemplateSchemaBodyIntoContentBody,
      moduleSpec
    }), {
      name: id,
      id,
      key,
      containerId,
      isInContainer: true,
      // Force any modules in a flex column (even ones set in the default layout of a template)
      // to be editable
      overrideable: true
    });
    return hydratedModule;
  });
};
const makeCachedSpecificModuleInsideFlexColumnSelector = (moduleId, containerId) => {
  const getSpecificSchemaWidgetInsideFlexColumn = makeGetSpecificSchemaWidgetInsideFlexColumn(moduleId, containerId);
  const getSpecificContentWidgetInsideFlexColumn = makeGetSpecificContentWidgetInsideFlexColumn(moduleId, containerId);
  return createSelector(getSpecificSchemaWidgetInsideFlexColumn, getSpecificContentWidgetInsideFlexColumn, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, getAllModuleSchemasByModuleId, (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody, allModuleSchemasByModuleId) => {
    const id = normalizedId(schemaWidget, contentWidget);
    const key = normalizedKey(schemaWidget, contentWidget);
    const moduleSpecId = schemaWidget && schemaWidget.get('module_id') || contentWidget && contentWidget.get('module_id');
    let moduleSpec;
    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];
    }

    // Hydrate widget from schema and contentWidget
    return defaultContentWidgetAttributes(schemaWidget, contentWidget).merge(schemaWidget)
    // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
    .set('body', getWidgetBodyFromSchema(schemaWidget)).merge(
    // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
    attributesPickedFromContentWidget(contentWidget, schemaWidget, {
      isUngatedToMergeTemplateSchemaBodyIntoContentBody,
      moduleSpec
    })).merge({
      // @ts-expect-error technically, these don't overlap, but that doesn't matter to us because we are building modules
      name: id,
      id,
      key,
      containerId,
      isInContainer: true,
      // Force any modules in a flex column (even ones set in the default layout of a template)
      // to be editable
      overrideable: true
    });
  });
};
const makeGetFlexColumnImmer = containerId => createSelector(getSchemaWidgetContainers.ungated, getContentWidgetContainersImmer, (schemaWidgetContainers, contentWidgetContainers) => contentWidgetContainers[containerId] || schemaWidgetContainers[containerId]);
const makeGetFlexColumn = containerId => createSelector(getSchemaWidgetContainers.gated, getContentWidgetContainers, (schemaWidgetContainers, contentWidgetContainers) => contentWidgetContainers.get(containerId) || schemaWidgetContainers.get(containerId));
const makeGetCachedModulesInsideSpecificFlexColumnImmer = containerId => {
  const cachedModuleSelectors = {};
  const getFlexColumn = makeGetFlexColumnImmer(containerId);
  return createSelector(getFlexColumn, getSchemaWidgetContainers.ungated, getContentWidgetContainersImmer, getAuth, getModuleSchemas, (flexColumn, schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumnsImmer(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    return flexColumn.widgets.map(module =>
    // Fall back to using "key" when id does not exist (in widgets from schema containers)
    // @ts-expect-error flex column apparently doesn't have key on it?
    Object.hasOwn(module, 'id') ? module.id : module.key)
    // TODO: Flatten this into one .map call
    .map((moduleId, order) => {
      if (!cachedModuleSelectors[moduleId]) {
        cachedModuleSelectors[moduleId] = makeCachedSpecificModuleInsideFlexColumnSelectorImmer(moduleId, containerId);
      }

      // Note this order set will force many more module reference updates that really needed.
      // Probably should move the order state _out_ of the module sooner or later to prevent that.
      // However, I (TF) am not super worried about perfecting the performance of flex columns,
      // and think we can live with this until flex columns slowly die off.
      const hydratedModule = Object.assign({}, cachedModuleSelectors[moduleId](fakedState), {
        order
      });
      return hydratedModule;
    });
  });
};
const makeGetCachedModulesInsideSpecificFlexColumn = containerId => {
  const cachedModuleSelectors = {};
  const getFlexColumn = makeGetFlexColumn(containerId);
  return createSelector(getFlexColumn, getSchemaWidgetContainers.gated, getContentWidgetContainers, getAuth, getModuleSchemas, (flexColumn, schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumns(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    return flexColumn.get('widgets').map(module =>
    // Fall back to using "key" when id does not exist (in widgets from schema containers)
    // @ts-expect-error flex column apparently doesn't have key on it, but ContentEditContainedWidget does
    module.has('id') ? module.get('id') : module.get('key')).map((moduleId, order) => {
      if (!cachedModuleSelectors[moduleId]) {
        cachedModuleSelectors[moduleId] = makeCachedSpecificModuleInsideFlexColumnSelector(moduleId, containerId);
      }
      // @ts-expect-error Probably won't bother fixing this one because the util it depends on is in JS
      return cachedModuleSelectors[moduleId](fakedState, {
        from: 'makeGetCachedModulesInsideSpecificFlexColumn'
      }).set('order', order);
      // Note this order set will force many more module reference updates that really needed.
      // Probably should move the order state _out_ of the module sooner or later to prevent that.
      // However, I (TF) am not super worried about perfecting the performance of flex columns,
      // and think we can live with this until flex columns slowly die off.
    });
  });
};

// TODO: Gate this one downstream inside of moduleSelectors
export const makeCachedModulesInsideAllFlexColumnsSelectorImmer = () => {
  const cachedModulesInContainerSelector = {};
  return createSelector(getSchemaWidgetContainers.ungated, getContentWidgetContainersImmer, getAuth, getModuleSchemas, (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumnsImmer(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    const modulesByContainer = Object.fromEntries(Object.keys(schemaWidgetContainers).map(containerId => {
      if (!cachedModulesInContainerSelector[containerId]) {
        cachedModulesInContainerSelector[containerId] = makeGetCachedModulesInsideSpecificFlexColumnImmer(containerId);
      }
      return [containerId, cachedModulesInContainerSelector[containerId](fakedState)];
    }));
    const containerModules = Object.fromEntries(Object.entries(modulesByContainer).map(([containerId, modulesInContainer]) => [containerId, Object.assign({}, schemaWidgetContainers[containerId], {
      type: 'container',
      id: containerId,
      widgets: modulesInContainer
    })]));

    // Convert to Map of modules by name
    const modulesInContainerMapping = Object.fromEntries(Object.values(modulesByContainer).flatMap(modules => modules.map(m => [m.name, m])));
    return Object.assign(modulesInContainerMapping, containerModules);
  });
};
export const makeCachedModulesInsideAllFlexColumnsSelector = () => {
  const cachedModulesInContainerSelector = {};
  return createSelector(getSchemaWidgetContainers.gated, getContentWidgetContainers, getAuth, getModuleSchemas, (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumns(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    const modulesByContainer = schemaWidgetContainers.map((container, containerId) => {
      if (!cachedModulesInContainerSelector[containerId]) {
        cachedModulesInContainerSelector[containerId] = makeGetCachedModulesInsideSpecificFlexColumn(containerId);
      }
      return cachedModulesInContainerSelector[containerId](fakedState);
    });
    const containerModules = modulesByContainer.map((modulesInContainer, containerId) => schemaWidgetContainers.get(containerId).merge({
      type: 'container',
      id: containerId,
      widgets: modulesInContainer
    }));

    // Convert to Map of modules by name
    // @ts-expect-error should not be using new
    return new ImmutableMap(modulesByContainer.valueSeq().flatMap(modules => modules.map(m => [m.get('name'), m]))).merge(containerModules).toObject();
  });
};

// Fancy multi-level memoization selectors for modules in a D&D area

export const getLayoutSectionWidgetsModuleMap = makeGatedSelector(getIsUngatedForImmerModuleReducer, createSelector(getLayoutSectionWidgets, layoutSectionWidgets => {
  return ImmutableMap(layoutSectionWidgets.valueSeq().flatMap(modulesForTree => {
    return modulesForTree.map(m => [m.get('name'), m]);
  }));
}), createSelector(getLayoutSectionWidgetsImmer, layoutSectionWidgets => {
  return Object.fromEntries(Object.values(layoutSectionWidgets).flatMap(modulesForTree => modulesForTree.map(m => [m.name, m])));
}));
const buildFakeModuleSliceStateForTrees = (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaLayoutSectionTrees,
      layoutSectionTrees: contentLayoutSectionTrees,
      layoutSectionWidgets
    })
  }),
  auth,
  moduleSchemas
});
const buildFakeModuleSliceStateForTreesImmer = (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: {
    present: {
      schemaLayoutSectionTrees,
      layoutSectionTrees: contentLayoutSectionTrees,
      layoutSectionWidgets
    }
  },
  auth,
  moduleSchemas
});
const buildFakeModuleSliceStateForSpecificTree = (treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaLayoutSectionTrees: ImmutableMap({
        [treeName]: schemaTree
      }),
      layoutSectionTrees: ImmutableMap(contentTree ? {
        [treeName]: contentTree
      } : {}),
      layoutSectionWidgets
    })
  }),
  auth,
  moduleSchemas
});
const buildFakeModuleSliceStateForSpecificTreeImmer = (treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: {
    present: {
      schemaLayoutSectionTrees: {
        [treeName]: schemaTree
      },
      layoutSectionTrees: contentTree ? {
        [treeName]: contentTree
      } : {},
      layoutSectionWidgets
    }
  },
  auth,
  moduleSchemas
});
const makeGetSpecificSchemaTreeImmer = treeName => createSelector(getSchemaLayoutSectionTrees.ungated, schemaTrees => schemaTrees[treeName]);
const makeGetSpecificSchemaTree = treeName => createSelector(getSchemaLayoutSectionTrees.gated, schemaTrees => schemaTrees.get(treeName));
const makeGetSpecificContentTreeImmer = treeName => createSelector(getContentLayoutSectionTrees.ungated, contentTrees => contentTrees[treeName]);
const makeGetSpecificContentTree = treeName => createSelector(getContentLayoutSectionTrees, contentTrees => contentTrees.get(treeName));
const makeGetTreeCellValueForImmer = (nodeName, treeName) => createSelector(makeGetSpecificSchemaTreeImmer(treeName), makeGetSpecificContentTreeImmer(treeName), (schemaTree, contentTree) => contentTree ? contentTree.findCell(nodeName).getValue() : schemaTree.findCell(nodeName).getValue());
const makeGetTreeCellValueFor = (nodeName, treeName) => createSelector(makeGetSpecificSchemaTree(treeName), makeGetSpecificContentTree(treeName), (schemaTree, contentTree) => contentTree ? contentTree.findCell(nodeName).getValue() : schemaTree.findCell(nodeName).getValue());
const makeGetContentTreeExistsForImmer = treeName => createSelector(getContentLayoutSectionTrees.ungated, contentTrees => Object.hasOwn(contentTrees, treeName));
const makeGetContentTreeExistsFor = treeName => createSelector(getContentLayoutSectionTrees.gated, contentTrees => contentTrees.has(treeName));
const makeGetLayoutSectionWidgetsModuleForImmer = moduleName => createSelector(getLayoutSectionWidgetsModuleMap.ungated, layoutSectionWidgetsModuleMap => layoutSectionWidgetsModuleMap[moduleName]);
const makeGetLayoutSectionWidgetsModuleFor = moduleName => createSelector(getLayoutSectionWidgetsModuleMap.gated, layoutSectionWidgetsModuleMap => layoutSectionWidgetsModuleMap.get(moduleName));
const makeGetSpecificModuleInsideTreeImmer = (moduleName, treeName) => {
  return createSelector(makeGetTreeCellValueForImmer(moduleName, treeName), makeGetContentTreeExistsForImmer(treeName), makeGetLayoutSectionWidgetsModuleForImmer(moduleName), getAllModuleSchemasByModuleId, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, (treeNodeValue, isFromContentTree, widgetFromLSW, allModuleSchemasByModuleId, isUngatedToMergeTemplateSchemaBodyIntoContentBody) => {
    const treeModule = exportCellAsModule(moduleName, treeNodeValue);

    // Normally, the "base module" should come from the `layout_section_widgets` array from the
    // combined edit API response. However, in cases where a module in a tree is missing from that
    // `layout_section_widgets` array, fall back to the tree module to prevent all kinds of things
    // from breaking.
    //
    // Note, ideally ^ should never happen, but this bug has happened time and time again and I'm
    // concerned future edge cases will continue to bring it back (hence the defensiveness)
    const baseModule = widgetFromLSW || treeModule;
    const moduleSpecId = baseModule.module_id;
    let moduleSpec;
    let fieldTypeNames = [];
    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];
      if (moduleSpec) {
        fieldTypeNames = getAllFieldNamesOnModuleSpec(moduleSpec);
      }
    }

    // Only pull in "content widget" overriding values if we're iterating over a tree on
    // the content model (so not when these are modules from schemaLayoutSectionTrees)
    const contentWidget = isFromContentTree ? treeModule : null;
    const semiSerializedWidgetThing = Object.assign({}, baseModule, {
      id: baseModule.name,
      layout_section_id: treeName
    }, attributesPickedFromContentWidgetImmer(contentWidget, widgetFromLSW, {
      sourcePrefix: 'params',
      moduleSpec,
      isUngatedToMergeTemplateSchemaBodyIntoContentBody
    }));
    if (contentWidget && contentWidget.params) {
      // Filter out all schema attributes, except if a custom module field name matches one of those
      // schema attributes (like if a custom module has a `type` field)
      const attributesToFilter = ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET.filter(attribute => !fieldTypeNames.includes(attribute));
      const filteredParams = Object.entries(contentWidget.params).reduce((obj, [key, val]) => {
        if (!attributesToFilter.includes(key)) {
          obj[key] = val;
        }
        return obj;
      }, {});
      mergeIn(semiSerializedWidgetThing, ['body'], filteredParams);
    }
    return semiSerializedWidgetThing;
  });
};
const makeGetSpecificModuleInsideTree = (moduleName, treeName) => {
  return createSelector(makeGetTreeCellValueFor(moduleName, treeName), makeGetContentTreeExistsFor(treeName), makeGetLayoutSectionWidgetsModuleFor(moduleName), getAllModuleSchemasByModuleId, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, getIsUngatedForCustomBreakpoints, (treeNodeValue, isFromContentTree, widgetFromLSW, allModuleSchemasByModuleId, isUngatedToMergeTemplateSchemaBodyIntoContentBody, isUngatedForCustomBreakpoints) => {
    const treeModule = fromJS(exportCellAsModule(moduleName, treeNodeValue));

    // Normally, the "base module" should come from the `layout_section_widgets` array from the
    // combined edit API response. However, in cases where a module in a tree is missing from that
    // `layout_section_widgets` array, fall back to the tree module to prevent all kinds of things
    // from breaking.
    //
    // Note, ideally ^ should never happen, but this bug has happened time and time again and I'm
    // concerned future edge cases will continue to bring it back (hence the defensiveness)
    const baseModule = widgetFromLSW || treeModule;
    const moduleSpecId = baseModule.get('module_id');
    let moduleSpec;
    let fieldTypeNames = [];
    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];
      if (moduleSpec) {
        fieldTypeNames = getAllFieldNamesOnModuleSpec(moduleSpec);
      }
    }

    // Only pull in "content widget" overriding values if we're iterating over a tree on
    // the content model (so not when these are modules from schemaLayoutSectionTrees)
    const contentWidget = isFromContentTree ? treeModule : null;

    // @ts-expect-error TypedMap doesn't mock merge, so the result of merge is incompatible with TypedMap
    let semiSerializedWidgetThing = baseModule.merge(Object.assign({
      id: baseModule.get('name'),
      layout_section_id: treeName
    }, attributesPickedFromContentWidget(contentWidget, widgetFromLSW, {
      sourcePrefix: 'params',
      moduleSpec,
      isUngatedToMergeTemplateSchemaBodyIntoContentBody
    })));
    if (contentWidget && contentWidget.get('params')) {
      // Filter out all schema attributes, except if a custom module field name matches one of those
      // schema attributes (like if a custom module has a `type` field)
      const attributesToFilter = ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET.filter(attribute => !fieldTypeNames.includes(attribute));
      const filteredParams = contentWidget.get('params').filter((value, key) => !attributesToFilter.includes(key));
      semiSerializedWidgetThing = semiSerializedWidgetThing.mergeIn(['body'], filteredParams);
    }
    if (isUngatedForCustomBreakpoints && contentWidget && contentWidget.get('styles')) {
      semiSerializedWidgetThing = semiSerializedWidgetThing.mergeIn(['styles'], contentWidget.get('styles'));
    }
    return semiSerializedWidgetThing;
  });
};
const makeGetCachedModulesInsideTreeImmer = treeName => {
  const cachedModuleSelectors = {};
  return createSelector(makeGetSpecificSchemaTreeImmer(treeName), makeGetSpecificContentTreeImmer(treeName), getLayoutSectionWidgetsImmer, getAuth, getModuleSchemas, (schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => {
    const tree = contentTree || schemaTree;
    const fakedState = buildFakeModuleSliceStateForSpecificTreeImmer(treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas);
    const allModuleNames = tree.allModules().map(node => node.getName());
    return allModuleNames.map(moduleName => {
      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleInsideTreeImmer(moduleName, treeName);
      }

      // Note this code previously would set the order on each module here, but I got rid of it
      // because I believe it was unnecessary _and_ it always forced every module in the tree
      // to get a new reference (every time this selector was executed)
      return cachedModuleSelectors[moduleName](fakedState);
    });
  });
};
const makeGetCachedModulesInsideTree = treeName => {
  const cachedModuleSelectors = {};
  return createSelector(makeGetSpecificSchemaTree(treeName), makeGetSpecificContentTree(treeName), getLayoutSectionWidgets, getAuth, getModuleSchemas, (schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => {
    const tree = contentTree || schemaTree;
    const fakedState = buildFakeModuleSliceStateForSpecificTree(treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas);
    return tree.allModules().map(node => node.getName()).map(moduleName => {
      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleInsideTree(moduleName, treeName);
      }

      // Note this code previously would set the order on each module here, but I got rid of it
      // because I believe it was unnecessary _and_ it always forced every module in the tree
      // to get a new reference (every time this selector was executed)

      return cachedModuleSelectors[moduleName](fakedState);
    });
  });
};

// TODO: Gate this one downstream inside of moduleSelectors
export const makeCachedModulesInsideLayoutSectionSelectorImmer = () => {
  const cachedModulesInTreeSelectors = {};
  return createSelector(getSchemaLayoutSectionTrees.ungated, getContentLayoutSectionTrees.ungated, getLayoutSectionWidgetsImmer, getAuth, getModuleSchemas, (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const modules = Object.keys(schemaLayoutSectionTrees).flatMap(treeName => {
      if (!cachedModulesInTreeSelectors[treeName]) {
        cachedModulesInTreeSelectors[treeName] = makeGetCachedModulesInsideTreeImmer(treeName);
      }
      const fakedState = buildFakeModuleSliceStateForTreesImmer(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
      return cachedModulesInTreeSelectors[treeName](fakedState);
    });

    // Convert array to obj
    return Object.fromEntries(modules.map(m => [m.name, m]));
  });
};
export const makeCachedModulesInsideLayoutSectionSelector = () => {
  const cachedModulesInTreeSelectors = {};
  return createSelector(getSchemaLayoutSectionTrees.gated, getContentLayoutSectionTrees.gated, getLayoutSectionWidgets, getAuth, getModuleSchemas, (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const modules = schemaLayoutSectionTrees.keySeq().flatMap(treeName => {
      if (!cachedModulesInTreeSelectors[treeName]) {
        cachedModulesInTreeSelectors[treeName] = makeGetCachedModulesInsideTree(treeName);
      }
      const fakedState = buildFakeModuleSliceStateForTrees(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
      return cachedModulesInTreeSelectors[treeName](fakedState);
    });

    // Convert List to obj
    return Object.fromEntries(modules.map(m => [m.get('name'), m]).toArray());
  });
};
const makeGetRootModuleForLayoutSectionImmer = treeName => createSelector(makeGetSpecificSchemaTreeImmer(treeName), makeGetTreeCellValueForImmer(treeName, treeName), (schemaTree, treeRootValue) => {
  const schemaTreeValue = schemaTree.getRootCell().getValue();
  return {
    type: 'layout_section',
    name: treeName,
    label: schemaTreeValue.label || treeRootValue.label,
    order: schemaTreeValue.order,
    line_number: treeRootValue.line_number
  };
});
const makeGetRootModuleForLayoutSection = treeName => createSelector(makeGetSpecificSchemaTree(treeName), makeGetTreeCellValueFor(treeName, treeName), (schemaTree, treeRootValue) => {
  const schemaTreeValue = schemaTree.getRootCell().getValue();
  // @ts-expect-error not typing this immutable usage
  return new ImmutableMap({
    type: 'layout_section',
    name: treeName,
    label: schemaTreeValue.label || treeRootValue.label,
    order: schemaTreeValue.order,
    line_number: treeRootValue.line_number
  });
});

// TODO: Cache this one downstream inside of moduleSelectors
export const makeCachedRootModulesForEachLayoutSectionSelectorImmer = () => {
  const rootModuleSelectorCache = {};
  return createSelector(getAllTreeNamesImmer, getSchemaLayoutSectionTrees.ungated, getContentLayoutSectionTrees.ungated, getLayoutSectionWidgetsImmer, getAuth, getModuleSchemas, (allTreeNames, schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const fakeState = buildFakeModuleSliceStateForTreesImmer(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
    return allTreeNames.map(treeName => {
      if (!rootModuleSelectorCache[treeName]) {
        rootModuleSelectorCache[treeName] = makeGetRootModuleForLayoutSectionImmer(treeName);
      }
      return rootModuleSelectorCache[treeName](fakeState);
    });
  });
};
export const makeCachedRootModulesForEachLayoutSectionSelector = () => {
  const rootModuleSelectorCache = {};
  return createSelector(getAllTreeNames, getSchemaLayoutSectionTrees.gated, getContentLayoutSectionTrees.gated, getLayoutSectionWidgets, getAuth, getModuleSchemas, (allTreeNames, schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const fakeState = buildFakeModuleSliceStateForTrees(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
    // @ts-expect-error This was the way that the immutable was already used
    return new List(allTreeNames).map(treeName => {
      if (!rootModuleSelectorCache[treeName]) {
        rootModuleSelectorCache[treeName] = makeGetRootModuleForLayoutSection(treeName);
      }
      return rootModuleSelectorCache[treeName](fakeState);
    });
  });
};
const ctaRegex = /\{\{\s*cta\('([\w-]+)'/gi;
const videoRegex = /{%\s+video_player\s+("[a-zA-Z0-9,_]+"|'[a-zA-Z0-9,_]+').*?\s+player_id=("(?:[a-zA-Z0-9]+)".*?|'(?:[a-zA-Z0-9]+)'.*?)\s+%}/;
export const isRichTextModule = (module, builtInMapping) => {
  return builtInMapping['rich_text'] === getImmutableOrPlain(module, 'module_id') || getImmutableOrPlain(module, 'id') === 'post_body';
};
export const getCtaIdsFromRichTextModuleImmer = module => {
  const rteHtml = get(module, ['body', 'html']);
  const ctaIds = Array.from(rteHtml.matchAll(ctaRegex)).map(([__fullMatch, idMatch]) => idMatch);
  return ctaIds;
};
export const getCtaIdsFromRichTextModule = module => {
  if (!isImmutable(module)) {
    return getCtaIdsFromRichTextModuleImmer(module);
  }
  const rteHtml = module.getIn(['body', 'html']);
  const ctaIds = Array.from(rteHtml.matchAll(ctaRegex)).map(([__fullMatch, idMatch]) => idMatch);
  return ctaIds;
};
export const isRichTextModuleWithCtaImmer = (module, builtInMapping) => {
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }
  const rteHtml = get(module, ['body', 'html']);
  return rteHtml ? !!rteHtml.match(ctaRegex) : false;
};
export const isRichTextModuleWithCta = (module, builtInMapping) => {
  if (!isImmutable(module)) {
    return isRichTextModuleWithCtaImmer(module, builtInMapping);
  }
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }
  const rteHtml = module.getIn(['body', 'html']);
  return rteHtml ? !!rteHtml.match(ctaRegex) : false;
};
export const isRichTextModuleWithHsVideoImmer = (module, builtInMapping) => {
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }
  const rteHtml = get(module, ['body', 'html']);
  return rteHtml ? !!rteHtml.match(videoRegex) : false;
};
export const isRichTextModuleWithHsVideo = (module, builtInMapping) => {
  if (!isImmutable(module)) {
    return isRichTextModuleWithHsVideoImmer(module, builtInMapping);
  }
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }
  const rteHtml = module.getIn(['body', 'html']);
  return rteHtml ? !!rteHtml.match(videoRegex) : false;
};
export const isCtaModule = (module, mapping) => {
  return mapping['cta'] === getImmutableOrPlain(module, 'module_id');
};
export const isVideoModuleWithHsVideoImmer = module => {
  const body = module.body;
  return body && has(body, ['hubspot_video', 'player_id']) && body.video_type === 'hubspot_video';
};
export const isVideoModuleWithHsVideo = module => {
  if (!isImmutable(module)) {
    return isVideoModuleWithHsVideoImmer(module);
  }
  const body = module.get('body');
  return body && body.hasIn(['hubspot_video', 'player_id']) && body.get('video_type') === 'hubspot_video';
};
export const isOrHasVideoModuleWithHsVideo = (module, builtInMapping) => isVideoModuleWithHsVideo(module) || isRichTextModuleWithHsVideo(module, builtInMapping);
const searchFieldTreeForRegexMatch = ({
  moduleFieldObject,
  schemaFieldsArray,
  regexArray,
  currentFieldKeyPath,
  fieldKeyPathsArray
}) => {
  if (!moduleFieldObject) {
    return fieldKeyPathsArray;
  }
  if (typeof moduleFieldObject !== 'object') {
    console.error(`currentFieldKeyPath "${currentFieldKeyPath}"'s moduleFieldObject is not an object \n moduleFieldObject value: ${moduleFieldObject}`);
    return fieldKeyPathsArray;
  }
  schemaFieldsArray.forEach(field => {
    if (field.children) {
      searchFieldTreeForRegexMatch({
        moduleFieldObject: getImmutableOrPlain(moduleFieldObject, field.name),
        schemaFieldsArray: field.children,
        regexArray,
        currentFieldKeyPath: currentFieldKeyPath.concat([field.name]),
        fieldKeyPathsArray
      });
    } else if (field.type === 'richtext') {
      const value = getImmutableOrPlain(moduleFieldObject, field.name);
      if (value && typeof value === 'string' && regexArray.some(regex => !!value.match(regex))) {
        fieldKeyPathsArray.push(currentFieldKeyPath.concat([field.name]));
      }
    }
  });
  return fieldKeyPathsArray;
};
export const searchModuleRichTextFieldsForRegexValidation = (moduleBody, moduleSchema, regexArray) => {
  if (!moduleBody || !moduleSchema) {
    return [];
  }
  return searchFieldTreeForRegexMatch({
    moduleFieldObject: moduleBody,
    schemaFieldsArray: moduleSchema.fields,
    regexArray,
    currentFieldKeyPath: [],
    fieldKeyPathsArray: []
  });
};
export const checkIfValueAtKeyMatchesRegex = (moduleBody, keyList, regexArray) => {
  if (!moduleBody) {
    return false;
  }
  let value;
  if (isImmutable(moduleBody)) {
    value = moduleBody.getIn(keyList);
  } else {
    value = get(moduleBody, keyList);
  }
  // @ts-expect-error TS thinks value is undefined still?
  return value && regexArray.some(r => !!value.match(r));
};
export const getKeysThatMatchRegex = (moduleBody, keyListArray, regexArray) => {
  if (!moduleBody || !keyListArray || !regexArray) {
    return [];
  }
  return keyListArray.filter(keyList => checkIfValueAtKeyMatchesRegex(moduleBody, keyList, regexArray));
};
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import I18n from 'I18n';
import { requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { hubdbTable } from 'ContentUtils/Routes';
import PortalIdParser from 'PortalIdParser';
import immutablePageSettingsErrors, { immerPageSettingsErrors } from 'ContentEditorUI/data/pageSettingsErrors';
// @ts-expect-error untyped
import { getContent } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getSiteSettingsBodyOverrideByDomain, getSiteSettings } from 'ContentEditorUI/redux/selectors/siteSettingsSelectors';
import { getActiveDomain, getContentModelHasValidDomain, getPagePrimaryDomain, getActiveDomainIsInternal } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { getHasAudienceAccessScope, getHasHubDbCustomDomainAccess, getHasDynamicPageAccess } from './authSelectors';
import { getActiveDomainName, getContentModelFeaturedImage, getContentModelFeaturedImageAltText, getLanguage } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentModelDynamicPageSlug, getContentModelDynamicPageTitle, getContentModelDynamicPageImage, getContentModelDynamicPageMeta, getContentModelDynamicPageUrl, getContentModelPageStylesheetsAsJSObject, getPageContentModel } from 'page-editor-ui/redux/selectors/pageContentModelSelectors';
import { buildFullUrl, buildLanguageString } from 'ContentEditorUI/utils/contentModelURLUtils';
// @ts-expect-error not yet migrated
import { getModuleAndUploadedImages } from 'ContentEditorUI/redux/selectors/featuredImageSelectors';
// @ts-expect-error not yet migrated
import { getImages } from 'ContentEditorUI/utils/helpers';
// @ts-expect-error not yet migrated
import { getHasCurrentLanguageInTranslatedContent } from 'ContentEditorUI/js/redux/selectors/translationSelectors';
import { basicSelector, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getIsBlogListingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
// @ts-expect-error not yet migrated
import * as CrmObjectTypes from 'page-editor-ui/constants/CrmObjectTypes';
// @ts-expect-error not yet migrated
import * as CrmObjectMetaTypes from 'page-editor-ui/constants/CrmObjectMetaTypes';
// @ts-expect-error not yet migrated
import { RESTRICTED_STANDARD_OBJECTS } from 'page-editor-ui/constants/CrmObjectTypes';
import { getContentModelDynamicPageSourceValue, getContentModelContentIsPublic, getDynamicPageSourceType } from './pageContentModelSelectors';
import { filterCrmObjectPropertiesUsableAsSlug, formatCrmObjectDynamicPageDataSourceIdForSave, isCustomCrmObjectDynamicPageSourceId
// @ts-expect-error not yet migrated
} from '../../utils/dynamicPagesUtils';
import { DataSourceTypes, CRM_SOURCE_TYPE, DATA_QUERY_TYPE, HUBDB_SOURCE_TYPE
// @ts-expect-error not yet migrated
} from 'page-editor-ui/constants/DynamicPagesDataSource';
import { getHasCrmDynamicPageAccess, getHasPasswordProtectionAccess, getHasAnyStylesheetAccess, getUserHasManagePrivateContentAccess } from './authSelectors';
import { getAuth, getIsUngatedForPatagonia, getHasLandingPagesMultiLangAccess, getHasSitePagesMultiLangAccess, getIsUngatedForImmerContentModel } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct } from 'ContentEditorUI/js/redux/selectors/portalDomainsSelectors';
// @ts-expect-error not yet migrated
import pageSettingsWarnings from 'ContentEditorUI/js/data/pageSettingsWarnings';
import { getCrmObjectTypesRequest, getHubDbTablesRequest
// @ts-expect-error not yet migrated
} from './pageRequestStatesSelectors';
import { getIsPublishOptionsModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { getDomainLanguage } from 'ContentComponents/utils/contentActionModalUtils';
import { checkIsLanguageSupportedByDeepL } from 'ContentComponents/utils/hubspotTranslateUtils';
import get from 'hs-lodash/get';
import size from 'hs-lodash/size';

//to migrate when pageContentModelSelectors is migrated

export const getPageSettings = basicSelector(state => state.pageSettings);
const createPageSettingsSelector = field => createSelector([getPageSettings], pageSettings => pageSettings[field]);
export const getPageSettingsAvailableHubDbTables = createPageSettingsSelector('availableHubDbTables');
export const getPageSettingsCrmObjectTypes = createPageSettingsSelector('availableCrmObjectTypes');
export const getPageSettingsDataQueries = createPageSettingsSelector('availableDataQueries');
export const getOriginalDynamicPageSourceValue = createPageSettingsSelector('originalDynamicPageSourceValue');
export const getIsDynamicPageSourceValuePortalSpecificCrmObject = createSelector([getDynamicPageSourceType, getPageSettingsCrmObjectTypes, getContentModelDynamicPageSourceValue], (dynamicPageSourceType, crmObjectTypes, contentModelDynamicPageSourceValue) => {
  if (dynamicPageSourceType === CRM_SOURCE_TYPE && crmObjectTypes) {
    const matchingObjectType = crmObjectTypes.find(crmObject => crmObject.name === contentModelDynamicPageSourceValue);
    if (matchingObjectType) {
      return matchingObjectType.metaType === CrmObjectMetaTypes.PORTAL_SPECIFIC;
    }
  }
  return false;
});

// Formats dynamicPageSourceId with p_ needed to fetch schema if we determine it is a custom object
export const getDynamicPageSourceIdToFetch = createSelector([getContentModelDynamicPageSourceValue, getIsDynamicPageSourceValuePortalSpecificCrmObject], (contentModelDynamicPageSourceValue, isDynamicPageSourceValuePortalSpecificCrmObject) => {
  return formatCrmObjectDynamicPageDataSourceIdForSave(contentModelDynamicPageSourceValue, isDynamicPageSourceValuePortalSpecificCrmObject);
});
export const getPageSettingsCrmObjectTypesOptions = createSelector([getPageSettingsCrmObjectTypes, getHasAudienceAccessScope, getHasPasswordProtectionAccess], (objectTypes, hasAudienceAccess, hasPasswordProtectionAccess) => {
  if (objectTypes) {
    return objectTypes.map(objectType => {
      const objectName = objectType.name;
      const objectNameUpperCased = objectName.toUpperCase();
      const objectTypeId = objectType.objectTypeId;
      const value = objectName;
      if (!hasAudienceAccess && !hasPasswordProtectionAccess && RESTRICTED_STANDARD_OBJECTS.includes(objectName)) {
        return null;
      }
      if (objectType.metaType === CrmObjectMetaTypes.HUBSPOT && CrmObjectTypes[objectNameUpperCased]) {
        const text = I18n.text(`crmObjectTypes.${objectNameUpperCased}.singular`);
        return {
          text,
          value,
          objectTypeId
        };
      } else if (objectType.metaType === CrmObjectMetaTypes.PORTAL_SPECIFIC) {
        const text = objectType.singularForm || objectName;
        return {
          text,
          value,
          objectTypeId
        };
      }
      return null;
    }).filter(objectType => !!objectType).sort((objectTypeA, objectTypeB) => {
      if (objectTypeA.text < objectTypeB.text) {
        return -1;
      }
      if (objectTypeA.text > objectTypeB.text) {
        return 1;
      }
      return 0;
    });
  }
  return [];
});

/*
  Temporary hack until crm-object-schemas/v3/schemas/{objectType} can fetch
  custom object types by simple names again. In the meantime we use the response
  from inbounddb-meta/v1/object-types/for-portal to get the FQN of the current
  objectType (stored by simple name on content model) that we can use to fetch
  the object schema.
*/
export const getDynamicPageSourceValueFullyQualifiedName = createSelector([getIsDynamicPageSourceValuePortalSpecificCrmObject, getContentModelDynamicPageSourceValue, getPageSettingsCrmObjectTypes], (isDynamicPageSourceValuePortalSpecificCrmObject, objectType, objectTypes) => {
  // To support pages that don't yet have the "p_" in their dynamicPageSourceId field, we still need to fetch by FQN
  if (isDynamicPageSourceValuePortalSpecificCrmObject && isCustomCrmObjectDynamicPageSourceId(objectType)) {
    return '';
  }
  if (objectTypes) {
    const objectTypeSchema = objectTypes.find(schema => {
      return schema.fullyQualifiedName === objectType || schema.name === objectType;
    });
    return objectTypeSchema ? objectTypeSchema.fullyQualifiedName : '';
  }
  return '';
});
export const getDynamicPagesDataQuery = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue, getPageSettingsDataQueries], (dynamicPageSourceType, contentModelDynamicPageSourceValue, dataQueries) => {
  if (dynamicPageSourceType !== DATA_QUERY_TYPE) {
    return undefined;
  }
  return dataQueries && dataQueries.find(q => q.path === contentModelDynamicPageSourceValue);
});
export const getDynamicPageSourceLocation = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue, getPageSettingsCrmObjectTypesOptions, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageSourceValue, crmObjectTypesOptions, dynamicPagesDataQuery) => {
  const PageSourceLocationGetterMap = {
    [HUBDB_SOURCE_TYPE]: value => hubdbTable(value),
    [CRM_SOURCE_TYPE]: value => {
      const PORTAL_ID = PortalIdParser.get();
      const crmObjectOption = crmObjectTypesOptions.find(option => option.value === value);
      if (!crmObjectOption) {
        return false;
      }

      // Kind of gross, but there are different URL patterns for these objects
      if (value === 'QUOTE') {
        return `/quotes/${PORTAL_ID}`;
      } else if (value === 'PRODUCT') {
        return `/settings/${PORTAL_ID}/sales/products`;
      }
      const {
        objectTypeId
      } = crmObjectOption;
      return `/contacts/${PORTAL_ID}/objects/${objectTypeId}`;
    },
    [DATA_QUERY_TYPE]: () => {
      const query = dynamicPagesDataQuery;
      const isCRM = query ? query.dataSourceType === DataSourceTypes.CRM_OBJECT : false;
      if (isCRM && query) {
        return `/contacts/${PortalIdParser.get()}/objects/${query.dataSourceInternalId}`;
      }
      return false;
    }
  };
  return PageSourceLocationGetterMap[dynamicPageSourceType] ? PageSourceLocationGetterMap[dynamicPageSourceType](contentModelDynamicPageSourceValue) : false;
});
export const getPageSettingsDataQueryOptions = createSelector([getPageSettingsDataQueries], availableDataQueries => {
  if (!availableDataQueries) {
    return [];
  }
  return availableDataQueries.map(query => {
    const dataSourceLabel = query.dataSourceType === DataSourceTypes.CRM_OBJECT ? `CRM: ${query.dataSourceLabel}` : query.dataSourceLabel;
    return {
      dataSourceLabel,
      text: query.label,
      value: query.path
    };
  });
});
export const getDynamicPagesDataSourceOptions = createSelector([getPageSettingsAvailableHubDbTables, getPageSettingsCrmObjectTypesOptions, getPageSettingsDataQueryOptions, getHasCrmDynamicPageAccess], (availableHubDbTables, crmObjectOptions, availableDataQueriesOptions, hasCrmDynamicPageAccess) => {
  const options = [];
  if (availableHubDbTables && crmObjectOptions) {
    const hubDbOptions = availableHubDbTables.map(table => {
      return {
        text: table.label,
        value: table.id.toString()
      };
    });
    options.push({
      text: 'HubDB',
      options: hubDbOptions
    });
  }
  if (availableDataQueriesOptions.length) {
    options.push({
      text: 'Data Queries',
      options: availableDataQueriesOptions
    });
  }
  if (hasCrmDynamicPageAccess && crmObjectOptions) {
    options.push({
      text: 'CRM',
      options: crmObjectOptions
    });
  }
  return options;
});
export const getCrmObjectSchema = createPageSettingsSelector('crmObjectSchema');
const getCrmObjectProperties = createSelector([getCrmObjectSchema], schema => {
  if (schema) {
    return schema.properties.filter(p => !p.hidden).sort((propertyA, propertyB) => {
      const propertyALabel = propertyA.label;
      const propertyBLabel = propertyB.label;
      if (propertyALabel < propertyBLabel) {
        return -1;
      }
      if (propertyALabel > propertyBLabel) {
        return 1;
      }
      return 0;
    });
  }
  return null;
});
export const getCrmObjectSlugPropertiesOptions = createSelector([getCrmObjectProperties, getCrmObjectSchema], (properties, objectSchema) => {
  if (properties) {
    return properties.filter(prop => filterCrmObjectPropertiesUsableAsSlug(prop, objectSchema)).map(property => ({
      text: property.label,
      value: property.name
    }));
  }
  return null;
});
export const getCrmObjectPropertiesTextTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.type === 'string' && property.fieldType === 'text').map(property => ({
      text: property.label,
      value: property.name
    }));
  }
  return [];
});
export const getCrmObjectPropertiesMultilineTextTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.type === 'string' && (property.fieldType === 'text' || property.fieldType === 'textarea')).map(property => ({
      text: property.label,
      value: property.name
    }));
  }
  return [];
});
export const getCrmObjectPropertiesFileTypeOptions = createSelector([getCrmObjectProperties], properties => {
  if (properties) {
    return properties.filter(property => property.type === 'string' && property.fieldType === 'file').map(property => ({
      text: property.label,
      value: property.name
    }));
  }
  return [];
});
export const getPageSettingsAvailablePageStylesheets = createPageSettingsSelector('availablePageStylesheets');
export const getCurrentDomain = createPageSettingsSelector('domain');
export const getPageSettingsDomainStylesheets = createSelector([getCurrentDomain, getSiteSettingsBodyOverrideByDomain, getPageSettings], (currentDomain, bodyOverrideByDomain, pageSettings) => {
  const bodyOverride = currentDomain && bodyOverrideByDomain && bodyOverrideByDomain[currentDomain];
  if (!bodyOverride) {
    return pageSettings.domainStylesheets;
  }
  const attachedStylesheets = bodyOverride['attachedStylesheets'];
  if (attachedStylesheets) {
    return attachedStylesheets;
  }
  return [];
});
export const getIsAllowedToPublishWithHubdb = createSelector([getHasHubDbCustomDomainAccess, getActiveDomainIsInternal, getDynamicPageSourceType, getContentModelDynamicPageSourceValue], (hasHubDbCustomDomainAccess, activeDomainIsInternal, dynamicPageSourceType, contentModelDynamicPageSourceValue) => {
  // If its not using a hubDB table, this is irrelevant
  if (!(dynamicPageSourceType === HUBDB_SOURCE_TYPE && contentModelDynamicPageSourceValue)) {
    return true;
  }
  // If they are not allowed to publish to a custom domain using hubdb,
  // and the active domain is custom, than disable the button.
  return activeDomainIsInternal || hasHubDbCustomDomainAccess;
});
export const getFeaturedImageThumbnailUrls = createPageSettingsSelector('featuredImageThumbnailUrls');
export const getPageSettingsSlugRaw = createPageSettingsSelector('slugRaw');
export const getPageSettingsFullUrl = createSelector([getSiteSettingsBodyOverrideByDomain, getActiveDomain, getPageSettingsSlugRaw, getLanguage], (bodyOverrideByDomain, activeDomain, slugRaw, language) => {
  if (bodyOverrideByDomain) {
    return buildFullUrl(bodyOverrideByDomain, activeDomain, slugRaw, language);
  }
  return '';
});
export const getPageLanguageSlug = createSelector([getSiteSettingsBodyOverrideByDomain, getLanguage, getActiveDomainName], (bodyOverrideByDomain, language, domain) => {
  if (bodyOverrideByDomain) {
    return buildLanguageString(bodyOverrideByDomain, language, domain);
  }
  return '';
});
export const getPageSettingslinkSlugToTitle = createPageSettingsSelector('linkSlugToTitle');
export const getPageSettingsPageExpiryRedirectName = createPageSettingsSelector('pageExpiryRedirectName');
export const getPageSettingsShowAllErrors = createPageSettingsSelector('showAllErrors');
export const getScrollToElement = createPageSettingsSelector('scrollToElement');
export const getPageSettingsTemplateStylesheets = createPageSettingsSelector('templateStylesheets');
export const getPageSettingsPageStylesheets = createSelector([getPageSettingsAvailablePageStylesheets, getContentModelPageStylesheetsAsJSObject], (availablePageStylesheets, contentModelPageStylesheetsAsJSObject) => {
  if (availablePageStylesheets) {
    return contentModelPageStylesheetsAsJSObject.map(contentModelPageStylesheet => {
      const stylesheetWithPath = availablePageStylesheets.find(availablePageStylesheet => availablePageStylesheet.id === contentModelPageStylesheet.id);
      return stylesheetWithPath || contentModelPageStylesheet;
    });
  }
  return contentModelPageStylesheetsAsJSObject;
});
export const getFeaturedImageOptions = createSelector([getContentModelFeaturedImage, getContentModelFeaturedImageAltText, getModuleAndUploadedImages, getFeaturedImageThumbnailUrls, getAuth], (featuredImage, featuredImageAltText, images, featuredImageThumbnailUrls, auth) => getImages(images, featuredImage, featuredImageAltText,
//getImages util still expects immutable
fromJS(featuredImageThumbnailUrls), auth));
export const getHasInvalidDynamicPageSourceValue = createSelector([getDynamicPagesDataSourceOptions, getContentModelDynamicPageSourceValue, getHubDbTablesRequest, getCrmObjectTypesRequest], (dynamicPagesDataSourceOptions, contentModelDynamicPageSourceValue, hubDbTablesRequest, crmObjectTypesRequest) => {
  if (!contentModelDynamicPageSourceValue || !requestSucceeded(hubDbTablesRequest) || !requestSucceeded(crmObjectTypesRequest)) return false;
  const allOptions = dynamicPagesDataSourceOptions.reduce((acc, dataSourceOption) => acc.concat(dataSourceOption.options), []);
  const foundOption = allOptions.some(option => option.value === contentModelDynamicPageSourceValue);
  return !foundOption;
});
export const getCannotUseCrmObjectTypeBecauseRestricted = createSelector([getContentModelContentIsPublic, getContentModelDynamicPageSourceValue, getDynamicPageSourceType], (contentModelContentIsPublic, contentModelDynamicPageSourceValue, dynamicPageSourceType) => dynamicPageSourceType === CRM_SOURCE_TYPE && RESTRICTED_STANDARD_OBJECTS.includes(contentModelDynamicPageSourceValue) && contentModelContentIsPublic);
const getAuthDataForPageSettingsErrors = createSelector([getIsUngatedForPatagonia, getHasAudienceAccessScope, getUserHasManagePrivateContentAccess], (isUngatedForPatagonia, hasAudienceAccessWrite, userHasManagePrivateContentAccess) => ({
  isUngatedForPatagonia,
  hasAudienceAccessWrite,
  userHasManagePrivateContentAccess
}));
export const getImmerPageSettingsErrors = createSelector([getPageContentModel.ungated, getPageSettings, getPagePrimaryDomain, getContentModelHasValidDomain, getIsAllowedToPublishWithHubdb, getHasCurrentLanguageInTranslatedContent, getIsBlogListingPage, getHasInvalidDynamicPageSourceValue, getCannotUseCrmObjectTypeBecauseRestricted, getIsPublishOptionsModalOpen, getAuthDataForPageSettingsErrors], (contentModel, pageSettings, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen, pageAuthDataForPageSettingsErrors) => {
  return immerPageSettingsErrors(contentModel, pageSettings, pageAuthDataForPageSettingsErrors, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, !isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen);
});
export const getImmutablePageSettingsErrors = createSelector([getPageContentModel.gated, getPageSettings, getPagePrimaryDomain, getContentModelHasValidDomain, getIsAllowedToPublishWithHubdb, getHasCurrentLanguageInTranslatedContent, getIsBlogListingPage, getHasInvalidDynamicPageSourceValue, getCannotUseCrmObjectTypeBecauseRestricted, getIsPublishOptionsModalOpen, getAuthDataForPageSettingsErrors], (contentModel, pageSettings, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen, pageAuthDataForPageSettingsErrors) => {
  return immutablePageSettingsErrors(
  // Types of upstream immutablePageSettingsErrors doesn't line up with
  // TypedMap<CombinedPageContentModelState>. Will clean this up later when
  // we finally move the pageSettingsErrors util to page-editor-ui.
  // @ts-expect-error ignoring for now, see above for context
  contentModel, pageSettings, pageAuthDataForPageSettingsErrors, primaryDomain, contentModelHasValidDomain, isAllowedToPublishWithHubdb, hasCurrentLanguageInCurrentTranslatedContent, !isBlogListingPage, hasInvalidDynamicPageSourceValue, cannotUseCrmObjectTypeBecauseRestricted, isPublishOptionsModalOpen);
});
export const getPageSettingsErrors = makeGatedSelector(getIsUngatedForImmerContentModel, getImmutablePageSettingsErrors, getImmerPageSettingsErrors);
export const getPageSettingsWarnings = createSelector([getShouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct], shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct => pageSettingsWarnings(shouldWarnPageOnPagebuilderDomainIncompatibilityWithProduct));
export const getCanSetPageExpiry = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
export const getCanSetPageAudience = createSelector([getHasAudienceAccessScope, getIsBlogListingPage], (hasAudienceAccessWrite, isBlogListingPage) => hasAudienceAccessWrite && !isBlogListingPage);
export const getCanSetDynamicPages = createSelector([getHasDynamicPageAccess, getIsBlogListingPage], (hasDynamicPageAccess, isBlogListingPage) => hasDynamicPageAccess && !isBlogListingPage);
export const getCanSetMetaDescription = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
const getHasMultilangAccessForPageType = createSelector([getIsSitePage, getHasLandingPagesMultiLangAccess, getHasSitePagesMultiLangAccess], (isSitePage, hasLandingPagesMultiLangAccess, hasSitePagesMultiLangAccess) => isSitePage ? hasSitePagesMultiLangAccess : hasLandingPagesMultiLangAccess);
export const getCanSetPageLanguage = createSelector([getIsBlogListingPage, getLanguage, getHasMultilangAccessForPageType], (isBlogListingPage, contentModelLanguage, hasMultilangAccessForPageType) => !isBlogListingPage && (Boolean(contentModelLanguage) || hasMultilangAccessForPageType));
export const getCanSetCanonicalUrl = createSelector([getIsBlogListingPage], isBlogListingPage => !isBlogListingPage);
export const getIsHtmlTitleDisabled = createSelector([getIsBlogListingPage], isBlogListingPage => isBlogListingPage);
export const getDynamicPageSlug = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSlug, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageSlug, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return get(dynamicPagesDataQuery, ['propertyDefinitionForPageSlug', 'label']);
  }
  return contentModelDynamicPageSlug;
});
export const getDynamicPageTitle = createSelector([getDynamicPageSourceType, getContentModelDynamicPageTitle, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageTitle, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return get(dynamicPagesDataQuery, ['propertyDefinitionForTitle', 'label']);
  }
  return contentModelDynamicPageTitle;
});
export const getDynamicPageMetaDescription = createSelector([getDynamicPageSourceType, getContentModelDynamicPageMeta, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPagesMeta, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return get(dynamicPagesDataQuery, ['propertyDefinitionForMetaDescription', 'label']);
  }
  return contentModelDynamicPagesMeta;
});
export const getDynamicPageFeaturedImage = createSelector([getDynamicPageSourceType, getContentModelDynamicPageImage, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageFeaturedImage, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return get(dynamicPagesDataQuery, ['propertyDefinitionForFeaturedImage', 'label']);
  }
  return contentModelDynamicPageFeaturedImage;
});
export const getDynamicPageCanonicalUrl = createSelector([getDynamicPageSourceType, getContentModelDynamicPageUrl, getDynamicPagesDataQuery], (dynamicPageSourceType, contentModelDynamicPageCanonicalUrl, dynamicPagesDataQuery) => {
  if (dynamicPageSourceType === DATA_QUERY_TYPE && dynamicPagesDataQuery) {
    return get(dynamicPagesDataQuery, ['propertyDefinitionForCanonicalUrl', 'label']);
  }
  return contentModelDynamicPageCanonicalUrl;
});
export const getHasAnyStylesheetsToEdit = createSelector([getPageSettingsDomainStylesheets, getPageSettingsPageStylesheets, getPageSettingsTemplateStylesheets], (domainStylesheets, pageStylesheets, templateStylesheets) => Boolean(domainStylesheets && size(domainStylesheets) > 0 || templateStylesheets && size(templateStylesheets) > 0 || pageStylesheets && size(pageStylesheets) > 0));
export const getCanEditStylesheets = createSelector([getHasAnyStylesheetAccess, getHasAnyStylesheetsToEdit], (hasAnyStylesheetsAccess, hasAnyStylesheetsToEdit) => Boolean(hasAnyStylesheetsAccess || hasAnyStylesheetsToEdit));
export const getCanUseAutotranslateForContentLanguage = createSelector([getContent, getLanguage, getSiteSettings], (content, contentLanguage, siteSettings) => {
  // we should be able to have these types inferred when migrated contentModel reducer
  const language = contentLanguage || getDomainLanguage(content, siteSettings);
  return !language || checkIsLanguageSupportedByDeepL({
    language
  });
});
import _, { any } from 'underscore';
import { createSelector } from 'reselect';
import States from 'ContentUtils/constants/States';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { PublicAccessRuleTypes } from 'ContentUtils/constants/PublicAccessRuleTypes';
// @ts-expect-error not yet migrated
import { getContextActiveDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { basicSelector, makeGatedSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { AiFlags } from 'ContentEditorUI/types/AiFlag';
// @ts-expect-error not yet migrated
import EditorConfigSingleton from '../../EditorConfigSingleton';
import ReducerDifferManager from 'ContentEditorUI/redux/ReducerDifferManager';

// Creating the gate selector directly because importing anything from
// authSelectors causes several circlular dependencies. Just going with this for
// now since this is just for the migration anyway and ultimately temporary.
const getIsUngatedForImmerContentModel = createSelector([state => state.auth], auth => {
  var _auth$gates;
  const contentModelGate = ReducerDifferManager.getGateForReduxSlice('contentModel');
  return contentModelGate ? auth === null || auth === void 0 || (_auth$gates = auth.gates) === null || _auth$gates === void 0 ? void 0 : _auth$gates.includes(contentModelGate) : false;
});
export const getImmutableContentModel = basicSelector(state => state.contentModel);
export const getImmerContentModel = basicSelector(state => state.contentModel);
export const getContentModel = makeGatedSelector(getIsUngatedForImmerContentModel, getImmutableContentModel, getImmerContentModel);
export const createContentModelSelector = key => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getImmutableContentModel], contentModel => {
  return contentModel.get(key);
}), createSelector([getImmerContentModel], contentModel => {
  return contentModel[key];
}));
export const getContentModelCampaignValue = createContentModelSelector('campaign');
export const getContentModelCampaignName = createContentModelSelector('campaignName');
export const getContentModelCampaignUtm = createContentModelSelector('campaignUtm');
export const getContentModelDomain = createContentModelSelector('domain');
export const getContentModelHtmlTitle = createContentModelSelector('htmlTitle');
export const getContentModelId = createContentModelSelector('id');
export const getContentModelIsPublished = createContentModelSelector('isPublished');
export const getContentModelMetaDescription = createContentModelSelector('metaDescription');
export const getContentModelName = createContentModelSelector('name');
export const getContentModelSlug = createContentModelSelector('slug');
export const getContentModelCanonicalUrl = createContentModelSelector('linkRelCanonicalUrl');
export const getContentModelPageExpiryDate = createContentModelSelector('pageExpiryDate');
export const getContentModelPageExpiryEnabled = createContentModelSelector('pageExpiryEnabled');
export const getContentModelPageExpiryRedirectId = createContentModelSelector('pageExpiryRedirectId');
export const getContentModelPageExpiryRedirectUrl = createContentModelSelector('pageExpiryRedirectUrl');
export const getPreviewKey = createContentModelSelector('previewKey');
export const getContentState = createContentModelSelector('state');
export const getSubcategory = createContentModelSelector('subcategory');
export const getTemplatePath = createContentModelSelector('templatePath');
export const getActiveDomainName = createSelector([getContentModelDomain, getContextActiveDomain], (contentModelDomain, activeDomain) => {
  return contentModelDomain || activeDomain.domain;
});
export const getActiveApexDomainName = createSelector([getContextActiveDomain], activeDomain => activeDomain.apexDomain);
export const getContentModelCampaign = createSelector([getContentModelCampaignValue, getContentModelCampaignName], (campaign, campaignName) => {
  return {
    campaign,
    campaignName
  };
});
export const getHasContentModelCampaign = createSelector([getContentModelCampaign], contentModelCampaign => {
  return !!contentModelCampaign.campaign;
});
export const getHasContentModel = createSelector([getContentModelId], contentModelId => contentModelId != null);
const _getAiFeatures = createContentModelSelector('aiFeatures');
export const getAiFeatures = makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([_getAiFeatures.gated], aiFeatures => aiFeatures ? aiFeatures.toJS() : null), _getAiFeatures.ungated);
export const getAiFeaturesAsRecord = createSelector(getAiFeatures, aiFeatures => {
  if (!aiFeatures) return {};
  const aiFeaturesAsRecord = {};
  Object.keys(AiFlags).forEach(flag => {
    if (aiFeatures.generatedByAi.includes(flag)) {
      aiFeaturesAsRecord[flag] = 'generatedByAi';
    } else if (aiFeatures.generatedWithAi.includes(flag)) {
      aiFeaturesAsRecord[flag] = 'generatedWithAi';
    }
  });
  return aiFeaturesAsRecord;
});
export const getHasUsedSlashOrHighlightCommands = createSelector([getAiFeaturesAsRecord], aiFeatures => !!aiFeatures[AiFlags.MAIN_CONTENT]);

// This is a substring search helper for the state selector below
const matchesState = (check, currentState) => {
  const allStates = _.values(States);
  const roughMatches = _(allStates).filter(state => state.lastIndexOf(check) > -1);
  return _(roughMatches).contains(currentState);
};
export const stateSelector = check => {
  return createSelector([getContentState], state => {
    if (check instanceof Array) {
      return any(check.map(item => matchesState(item, state)));
    }
    return matchesState(check, state);
  });
};

// EMAIL AB TEST SELECTORS

export const getImmutableEmailContentModel = basicSelector(state => state.contentModel);
export const getImmerEmailContentModel = basicSelector(state => state.contentModel);
export const createEmailContentModelSelector = field => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getImmutableEmailContentModel], contentModel => {
  return contentModel.get(field);
}), createSelector([getImmerEmailContentModel], contentModel => {
  return contentModel[field];
}));
export const getContentAbTestPercentage = createEmailContentModelSelector('abTestPercentage');
export const getContentAbSuccessMetric = createEmailContentModelSelector('abSuccessMetric');
export const getContentAbHoursToWait = createEmailContentModelSelector('abHoursToWait');
export const getContentAbSampleSizeDefault = createEmailContentModelSelector('abSampleSizeDefault');
export const getContentAbSamplingDefault = createEmailContentModelSelector('abSamplingDefault');

// LANGUAGE SELECTORS
export const getLanguage = createContentModelSelector('language');

// PUBLISH SELECTORS

export const getImmutablePublishContentModel = basicSelector(state => state.contentModel);
export const getImmerPublishContentModel = basicSelector(state => state.contentModel);
export const createPublishContentModelSelector = field => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getImmutablePublishContentModel], contentModel => {
  return contentModel.get(field);
}), createSelector([getImmerPublishContentModel], contentModel => {
  return contentModel[field];
}));
export const getPublishDate = createPublishContentModelSelector('publishDate');
export const getPublishImmediatelyValue = createPublishContentModelSelector('publishImmediately');
export const getShouldPublishImmediately = createSelector([getContentState, getPublishImmediatelyValue], (contentState, publishImmediatelyValue) => {
  const isDraft = StateStatusGroups.DRAFT.includes(contentState);
  const publishImmediately = publishImmediatelyValue;
  if (typeof publishImmediately !== 'boolean' && isDraft) {
    return true;
  }
  return publishImmediately;
});

// FEATURED IMAGE SELECTORS

export const getImmutableFeaturedImageContentModel = state => state.contentModel;
export const getImmerFeaturedImageContentModel = basicSelector(state => state.contentModel);
export const createFeaturedImageContentModelSelector = field => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getImmutableFeaturedImageContentModel], contentModel => {
  return contentModel.get(field);
}), createSelector([getImmerFeaturedImageContentModel], contentModel => {
  return contentModel[field];
}));
export const getContentModelFeaturedImage = createFeaturedImageContentModelSelector('featuredImage');
export const getContentModelFeaturedImageAltText = createFeaturedImageContentModelSelector('featuredImageAltText');
export const getContentModelUseFeaturedImage = createFeaturedImageContentModelSelector('useFeaturedImage');

// Misc content model selectors for properties that are not set in ContentEditorUI

export const getImmutableMiscContentModel = basicSelector(state => state.contentModel);
export const getImmerMiscContentModel = basicSelector(state => state.contentModel);
export const createMiscContentModelSelector = field => makeGatedSelector(getIsUngatedForImmerContentModel, createSelector([getImmutableMiscContentModel], contentModel => {
  return contentModel.get(field);
}), createSelector([getImmerMiscContentModel], contentModel => {
  return contentModel[field];
}));
export const getTranslatedFromId = createMiscContentModelSelector('translatedFromId');

// Dynamic page selectors
export const getDynamicPageDataSourceId = createMiscContentModelSelector('dynamicPageDataSourceId');
export const getContentScheduledUpdateDate = createMiscContentModelSelector('scheduledUpdateDate');

// Memberships content access selectors
export const getContentModelPublicAccessRules = createMiscContentModelSelector('publicAccessRules');
export const getIsPublic = createSelector([getContentModelPublicAccessRules], publicAccessRules => publicAccessRules.some(publicAccessRule => publicAccessRule.type === PublicAccessRuleTypes.PUBLIC));
export const getPublicAccessRulesWallPreviewEnabled = createSelector([getContentModelPublicAccessRules], publicAccessRules => !!publicAccessRules && publicAccessRules.some(publicAccessRule => publicAccessRule.wallPreviewEnabled));
export const getContentModelPublicAccessRulesEnabled = createMiscContentModelSelector('publicAccessRulesEnabled');

// Mirrors BE BlogPostDataWallWrapperTag.showDataWall conditions
// https://git.hubteam.com/HubSpot/cos-renderer/blob/9c135a0af2c3771d63435f4d7fed4a2fb0652a57/ContentHubL/src/main/java/com/hubspot/content/hubl/tag/BlogPostDataWallWrapperTag.java#L131
export const getShowDataWall = createSelector([getContentModelPublicAccessRulesEnabled, getIsPublic, getPublicAccessRulesWallPreviewEnabled], (publicAccessRulesEnabled, isPublic, publicAccessRulesWallPreviewEnabled) => (publicAccessRulesEnabled || !isPublic) && publicAccessRulesWallPreviewEnabled);
export const getEditorConfigContentName = state => {
  if (EditorConfigSingleton.getIsOnScalableEditor() && EditorConfigSingleton.getIn(['redux', 'selectors']).getContentName) {
    return EditorConfigSingleton.getIn(['redux', 'selectors']).getContentName(state);
  }
  return null;
};
export const getContentName = createSelector([getEditorConfigContentName, getContentModelName], (contentNameFromEditorConfig, contentModelName) => {
  if (contentNameFromEditorConfig) {
    return contentNameFromEditorConfig;
  }
  return contentModelName;
});